<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Vendor Advance Request</h4>
          <form>
            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Project <span class="text-danger">*</span></h6>
                <multiselect
                  v-validate="'required'"
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  autocomplete="off"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                  >
                    {{ errors.first("Project Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2 mx-auto">
                <h6>Vendor <span class="text-danger">*</span></h6>
                <vue-simple-suggest
                  v-validate="'required'"
                  :min-length="0"
                  v-model="form.vendorName"
                  :list="vendorList"
                  display-attribute="vendor_name"
                  value-attribute="vendor_id"
                  :filter-by-query="true"
                  name="Vendor Name"
                  v-on:blur="checkVendor"
                  v-on:focus="vendor_flag = true"
                  autocomplete="off"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                  >
                    {{ errors.first("Vendor Name") }}
                  </span>
                  <span class="text-danger text-sm">
                    {{ vendornotfound }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Invoice Number (Optional)</h6>
                <vs-input
                  autocomplete="off"
                  type="text"
                  name="Amount"
                  v-model="form.invoice_number"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Invoice Number')"
                  >
                    {{ errors.first("Invoice Number") }}
                  </span>
                  <span class="text-danger text-sm" v-if="dupinvoice != ''">
                    {{ dupinvoice }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Invoice Date (Optional)</h6>
                <datepicker
                  placeholder="From Date"
                  @selected="onSelectDate($event)"
                  format="dd-MM-yyyy"
                  v-model="form.invoice_date"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Invoice Date')"
                  >
                    {{ errors.first("Invoice Date") }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Payment Due Date <span class="text-danger">*</span></h6>
                <datepicker
                  placeholder="To Date"
                  :disabledDates="endDisabledDates"
                  v-model="form.payment_due_date"
                  format="dd-MM-yyyy"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Payment Due Date')"
                  >
                    {{ errors.first("Payment Due Date") }}
                  </span>
                </p>
              </div>
              <div
                class="col-12 col-md-4 col-sm-4 mb-2"
                v-if="
                  isShootLocationActive == true ||
                    isShootLocationActive == 'true'
                "
              >
                <h6>Set Name (Optional)</h6>
                <multiselect
                  label="location_name"
                  class="w-full"
                  track-by="location_id"
                  v-model="form.shootLocation"
                  name="location_name"
                  :options="shootLocationList"
                  :searchable="true"
                  :allow-empty="true"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('location_name')"
                  >
                    {{ errors.first("location_name") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Country <span class="text-danger">*</span></h6>
                <multiselect
                  label="country_name"
                  class="w-full"
                  track-by="country_id"
                  v-model="form.countryValue"
                  name="Priority"
                  :options="countryList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  @select="countrySelected"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2l">
                <h6>City <span class="text-danger">*</span></h6>
                <multiselect
                  :options="cityList"
                  v-model="form.cityValue"
                  track-by="city_id"
                  label="city_name"
                  placeholder="City Name"
                  name="City Name"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('City Name')"
                  >
                    {{ errors.first("City Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Priority <span class="text-danger">*</span></h6>
                <multiselect
                  label="priority_name"
                  class="w-full"
                  track-by="priority_id"
                  v-model="form.priorityValue"
                  name="Priority"
                  :options="priority"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Category (Optional)</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                  >
                    {{ errors.first("Category Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Subcategory (Optional)</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                  >
                    {{ errors.first("Subcategory Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Mode of payment <span class="text-danger">*</span></h6>
                <multiselect
                  label="payment_mode_name"
                  class="w-full"
                  track-by="payment_mode_id"
                  v-model="form.modeOfPayment"
                  name="Mode of payment"
                  :options="paymentMode"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Mode of payment')"
                  >
                    {{ errors.first("Mode of payment") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Amount <span class="text-danger">*</span></h6>
                <vs-input
                  v-validate="'required'"
                  type="number"
                  name="Amount"
                  @input="totalAmount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                  >
                    {{ errors.first("Amount") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Tax (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="form.amount == 0"
                      label="gst_name"
                      class="w-full"
                      track-by="gst_id"
                      v-model="form.gst_id"
                      name="gst"
                      @select="gstCalculate"
                      :options="gstList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Priority')"
                      >
                        {{ errors.first("Priority") }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="gst_name != 'Manual Amount'"
                      type="number"
                      name="GST"
                      v-model="form.gst_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>TDS (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="!form.amount"
                      label="tds_name"
                      class="w-full"
                      track-by="tds_id"
                      v-model="form.tds_id"
                      name="TDS"
                      @select="tdsCalculate"
                      :options="tdsList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('TDS')"
                      >
                        {{ errors.first("TDS") }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="tds_name != 'Manual Amount'"
                      type="number"
                      name="TDS"
                      v-model="form.tds_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Total Amount</h6>
                <vs-input
                  type="number"
                  name="GST"
                  v-model="form.total_amount"
                  class="w-full"
                  placeholder=""
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Comment <span class="text-danger">*</span></h6>
                <vs-textarea
                  v-validate="'required'"
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                  >
                    {{ errors.first("Comment") }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-md-6 mb-2 doc-upload">
                <h6>Upload Document (Optional)</h6>
                <vue-dropzone
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-error-multiple="vdropzoneError"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-added-files="vdropzoneFilesAdded"
                  @vdropzone-success-multiple="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="feather icon-trash mt-2"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="openCropper()"
                  class="ml-4 mt-2"
                >
                  Crop Images
                </vs-button>
              </div>
              <div v-if="showCropperModal == true">
                <image-cropper :allSelectedImages="allSelectedImages" />
              </div>
              <div class="col-12 col-md-4 col-sm-4 mb-2" style="display: none">
                <h6>Already Approved</h6>
                <vs-switch v-model="isAlreadyPaid" />
              </div>
            </div>
          </form>

          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mr-3  mb-3 w-full sm:w-auto"
            >
              Submit
            </vs-button>
            <vs-button
              color="warning"
              type="border"
              class=" mb-3 w-full sm:w-auto"
              @click.prevent="clearForm"
            >
              Reset
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import VueSimpleSuggest from "vue-simple-suggest";
import ImageCropper from "../projects/imageCropper.vue";
import UserService from "@/services/UserService.js";
import MethodService from "@/services/methodService.js";
import ExpenseService from "@/services/expenseService.js";
import projectService from "@/services/projectService.js";
import CountryService from "@/services/countryService.js";
import CategoryService from "@/services/categoryService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
    projectList: Array,
    currencyList: Array,
    shootLocationList: Array,
    vendorList: Array,
    countryList: Array,
    alternateOrg: Array,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSimpleSuggest,
    ImageCropper,
  },

  beforeMount() {
    this.getMasterList();
    this.organization_id = localStorage.getItem("OrganizationID");
    this.dropzoneOptionsFiles["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      showCropperModal: false,
      allSelectedImages: [],
      showOnBehalf: false,
      vendor_flag: false,
      vendornotfound: "",
      vendornotfoundold: 0,
      dupinvoice: "",
      vendoridtemp: 0,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      submitStatus: true,
      isAlreadyPaid: false,
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      toDateDisable: true,
      gstList: [],
      tdsList: [],
      tds_name: "",
      form: {
        invoice_number: "",
        payment_due_date: "",
        invoice_date: "",
        gst_id: "",
        gst_amount: 0,
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        vendorName: "",
        categoryValue: {},
        amount: 0,
        comment: "",
        priorityValue: {},
        modeOfPayment: {},
        countryValue: {},
        cityValue: {},
        onBehalfId: 11,
        shootLocation: "",
        // default_alt_org_id: "",
        subcategoryValue: null,
      },
      priority: [],
      paymentMode: [],
      approverList: [],
      categoryList: [],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles:
          ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp,.doc,.docx",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      uploadedDocumentArray: [],
      gst_name: "",
      gst_percentage: 0,
      cityList: [],
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (!this.form.invoice_number) {
          delete this.form.invoice_number;
        }

        if (!this.form.invoice_date) {
          delete this.form.invoice_date;
        }

        setTimeout(() => {
          if (!this.form.categoryValue) {
            delete this.form.categoryValue;
          }
          if (!this.form.subcategoryValue) {
            delete this.form.subcategoryValue;
          }
        }, 1000);

        if (!this.form.gst_id) {
          delete this.form.gst_id;
        }
        if (!this.form.gst_amount) {
          delete this.form.gst_amount;
        }
        if (!this.form.tds_id) {
          delete this.form.tds_id;
        }
        if (!this.form.tds_amount) {
          delete this.form.tds_amount;
        }
        if (!this.form.shootLocation) {
          delete this.form.shootLocation;
        }

        if (this.form.vendorName != "" && this.vendor_flag) {
          let vendorCheck = this.vendorList.filter((x) => {
            let ss1 = x.vendor_name;
            let ss2 = this.form.vendorName;
            ss1 = ss1.trim();
            ss2 = ss2.trim();

            let arr = ss1.split(ss2.toLowerCase());
            return arr.length > 0 ? true : false;
          });

          if (vendorCheck.length == 0) {
            if (this.form.vendorName != this.vendornotfoundold) {
              this.vendornotfoundold = this.form.vendorName;

              this.vendornotfoundold = this.vendornotfoundold.trim();
              this.vendornotfound =
                "Vendor named " + this.vendornotfoundold + " not found!";
            }
          } else {
            this.vendornotfound = "";
          }
        }

        this.dupinvoice = "";

        if (this.vendoridtemp > 0 && this.form.projectName) {
          ExpenseService.checkInvoice(
            this.vendoridtemp,
            this.form.invoice_number,
            this.form.projectName.project_id
          )
            .then((response) => {
              const { data } = response;
              this.dupinvoice = data.Message;
            })
            .catch((error) => {});
        }

        let len = Object.keys(this.form).length;

        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] != "invoice_number" &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    isCreditNote() {
      return 1;
    },

    isShootLocationActive() {
      return window.localStorage.getItem("isShootLocation");
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);

    this.endDisabledDates["to"] = d;
    if (this.isCreditNote == 0) {
      this.form.invoice_number = "1";
      this.form.payment_due_date = "1";
      this.form.invoice_date = "1";
      this.form.gst_id = "1";
      this.form.gst_amount = "1";
    }
    setTimeout(() => {
      if (this.countryList) {
        this.form.countryValue = this.countryList[0];
        this.countrySelected(this.countryList[0]);
      }
    }, 1000);
    eventBus.$on("UploadCropedImage", (payload) => {
      this.$refs.myVueDropzoneFile.removeFile(payload.fileToRemove);
      this.$refs.myVueDropzoneFile.addFile(payload.fileToAdd);
    });
  },
  methods: {
    countrySelected(country) {
      this.form.cityValue = {};
      this.cityList = [];
      CountryService.getCityByCountry(country.country_id)
        .then((response) => {
          if (response.data.Status) {
            this.cityList = response.data.data;
          }
        })
        .catch((error) => console.log("Error =>", error.message));
    },
    openCropper() {
      this.showCropperModal = true;
    },

    checkVendor() {
      this.vendor_flag = false;
      if (this.form.vendorName != "") {
        let vendorCheck = this.vendorList.filter((x) => {
          let ss1 = x.vendor_name;
          let ss2 = this.form.vendorName;
          ss1 = ss1.trim();
          ss2 = ss2.trim();
          return ss1.toLowerCase() == ss2.toLowerCase();
        });

        if (vendorCheck.length == 0) {
          if (this.form.vendorName != this.vendornotfoundold) {
            this.vendornotfoundold = this.form.vendorName;

            this.vendornotfoundold = this.vendornotfoundold.trim();
            this.vendornotfound =
              "Vendor named " + this.vendornotfoundold + " not found!";
          }
        } else {
          this.vendoridtemp = vendorCheck[0].vendor_id;
        }
      }

      let tempinvoice = this.form.invoice_number + "";

      let leninvoice = tempinvoice.length;
      if (leninvoice > 3) {
        if (this.vendoridtemp > 0 && this.form.projectName) {
          ExpenseService.checkInvoice(
            this.vendoridtemp,
            this.form.invoice_number,
            this.form.projectName.project_id
          )
            .then((response) => {
              const { data } = response;
              this.dupinvoice = data.Message;
              console.log(data, "dataCalled");
            })
            .catch((error) => {});
        }
      }
    },

    gstCalculate(selected) {
      this.gst_name = selected.gst_name;
      this.gst_percentage = selected.gst_percentage;
      this.form.gst_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(4) : 0;

      let totalAmount =
        (parseFloat(selected.gst_percentage).toFixed(4) * amount) / 100;
      this.form.gst_amount = parseFloat(totalAmount).toFixed(4);
      this.totalAmount();
    },

    tdsCalculate(selected) {
      this.tds_name = selected.tds_name;
      this.tds_percentage = selected.tds_percentage;
      this.form.tds_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(2) : 0;

      let totalAmount = (parseFloat(selected.tds_percentage) * amount) / 100;
      this.form.tds_amount = parseFloat(totalAmount);
      this.totalAmount();
    },

    totalAmount() {
      let amount =
        this.form.amount != "" ? parseFloat(this.form.amount).toFixed(4) : 0;

      this.form.total_amount =
        ((this.form.gst_amount ? parseFloat(this.form.gst_amount) : 0) * 10 +
          parseFloat(amount) * 10 -
          (this.form.tds_amount ? parseFloat(this.form.tds_amount) : 0) * 10) /
        10;
    },

    onSelectDate: function($event) {
      var date1 = new Date($event);
      var date2 = new Date(this.form.payment_due_date);
      var diffDays = date2.getDate() - date1.getDate();
      if (diffDays < 0) {
        this.form.payment_due_date = "";
      }
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.toDateDisable = false;
    },

    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == true) {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMasterList: function() {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.priority = data.data.priority;
            if (this.priority.length > 0) {
              this.form.priorityValue = this.priority[0];
            }

            this.paymentMode = data.data.payment_mode;
            if (this.paymentMode.length > 0) {
              this.form.modeOfPayment = this.paymentMode[0];
            }
            this.gstList = data.data.gst;
            this.tdsList = data.data.tds;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMapList: function(id) {
      UserService.getMapList({ project_id: id })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.approverList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    onSuggestSelect: function(selected) {
      // this.getSubCategory(selected);
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },

    getDataByUserAndProject(project_id, user_id) {
      projectService
        .getDataByUserAndProject(project_id, user_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.showOnBehalf =
              data.data[0].is_moderator_user ||
              data.data[0].is_admin_manager_user
                ? true
                : false;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    onProjectSelect: function(selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      // if (this.form.cityValue) this.getCategory(this.form.cityValue);
      this.getMapList(this.project_id);
      this.getDataByUserAndProject(
        this.project_id,
        window.localStorage.getItem("user_id")
      );
    },

    getAllCategoryListByProject: function(id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    submitForm: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let payload = {
            project_id: this.form.projectName
              ? this.form.projectName.project_id
              : 0,
            po_name: this.form.vendorName ? this.form.vendorName : "",
            item_name: "-",
            company_name: "-",
            customFieldValues: [],
            category_id: this.form.subcategoryValue
              ? this.form.subcategoryValue.category_id
              : null,
            company_address: "-",
            price: this.form.amount,
            currency: "INR",
            sgst: "0",
            cgst: "0",
            total: this.form.amount,
            purchase_image: this.uploadedDocument,
            country_id: this.form.countryValue.country_id || null,
            city_id: this.form.cityValue.city_id || null,
            purchase_description: this.form.comment,
            purchase_status: "0",
            approved_percentage: "0",
            purchase_active: "1",
            location_id: this.form.shootLocation
              ? this.form.shootLocation.location_id
              : "",
            default_alt_org_id: this.form.default_alt_org_id
              ? this.form.default_alt_org_id.default_alt_org_id
              : "",

            payment_mode: this.form.modeOfPayment
              ? this.form.modeOfPayment.payment_mode_id
              : "",
            payment_mode_description: "-",
            priority: this.form.priorityValue
              ? this.form.priorityValue.priority_id
              : "",
            is_var: "1",
            approver_id: "0",
            isAlreadyPaid: this.isAlreadyPaid ? 1 : 0,
            vendorName: this.form.vendorName,
            vendor_id: 0,
            invoice_number: this.form.invoice_number
              ? this.form.invoice_number
              : "",
            payment_due_date: this.form.payment_due_date
              ? this.form.payment_due_date
              : "",
            invoice_date: this.form.invoice_date ? this.form.invoice_date : "",
            gst_id: this.form.gst_id ? this.form.gst_id.gst_id : 0,
            gst_amount: this.form.gst_amount ? this.form.gst_amount : 0,
            tds_id: this.form.tds_id ? this.form.tds_id.tds_id : 0,
            tds_amount: this.form.tds_amount ? this.form.tds_amount : 0,
            total_amount: this.form.total_amount,
          };

          if (this.isCreditNote == 0) {
            payload.invoice_number = "";
            payload.payment_due_date = "";
            payload.invoice_date = "";
            payload.gst_id = "0";
            payload.gst_amount = 0;
            payload.tds_id = "0";
            payload.tds_amount = 0;
            payload.total_amount = this.form.amount;
          }
          let vendorCheck = this.vendorList.filter((x) => {
            return x.vendor_name == this.form.vendorName;
          });
          if (vendorCheck.length > 0) {
            payload["vendor_id"] = vendorCheck[0].vendor_id;
          }
          ExpenseService.addExpense(payload)
            .then((response) => {
              const { data } = response;

              this.$vs.loading.close();
              if (data.Status == true) {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });

                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
            });
        }
      });
    },

    getSubcategoryList: function(ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (data.data.length == 1) {
              this.form.subcategoryValue = this.subcategoryList[0];
            } else {
              this.form.subcategoryValue = "";
            }
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function(file) {
      console.log("file jitu :", file);
    },

    vdropzoneSuccessFile: function(file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },

    clearFile: function() {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
    },

    vdropzoneComplete: function(response) {
      for (let i = 0; i < response.length; i++) {
        this.selectedFilesUUID.push(response[i].upload.uuid);
        if (!response[i].cropped) {
          this.allSelectedImages.push(response[i]);
        }
      }
      this.showCropperModal = false;
    },

    vdropzoneError: function(file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },

    vremoved: function(file) {
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
    },

    clearForm: function() {
      this.form = {
        invoice_number: "",
        payment_due_date: "",
        invoice_date: "",
        gst_id: "",
        gst_amount: 0,
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        vendorName: "",
        categoryValue: "",
        amount: 0,
        comment: "",
        priorityValue: "",
        modeOfPayment: "",
        countryValue: "",
        onBehalfId: 11,
        shootLocation: "",
        subcategoryValue: "",
      };
      this.dupinvoice = "";
      this.submitStatus = true;
      this.reason = "";
      this.uploadedDocument = "-";
      this.clearFile();
      if (this.priority.length > 0) {
        this.form.priorityValue = this.priority[0];
      }
      if (this.paymentMode.length > 0) {
        this.form.modeOfPayment = this.paymentMode[0];
      }
      if (this.countryList.length > 0) {
        this.form.countryValue = this.countryList[0];
      }
      this.$validator.reset();
    },

    // getCategory(city) {
    //   this.form.categoryValue = null;
    //   this.form.subcategoryValue = null;
    //   if (!this.form.projectName) return;
    //   CategoryService.getProjectMainCategoriesByCity({
    //     project_id: this.form.projectName.project_id,
    //     city_id: city.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.categoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },

    // getSubCategory(category) {
    //   CategoryService.getProjectSubCategoriesByParentAndCity({
    //     parent_id: category.category_id,
    //     project_id: this.form.projectName.project_id,
    //     city_id: this.form.cityValue.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.subcategoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },
  },
};
</script>
