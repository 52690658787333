<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name:
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-end items-center">
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <h5 class="mr-5">
              Total Amount:
              <span class="text-primary font-bold">{{
                totalPo.toLocaleString("en-IN")
              }}</span>
            </h5>
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <!-- <vx-tooltip text="Export Excel">
              <a
                :href="excelurl+`/v1/expenses/excel/download?project_id=${this.projectID}&approver_id=1search=${searchQuery}`"
                target="_blank"
              >

              </a>
            </vx-tooltip>-->
            <vs-button class="px-4" @click.native="downloadExcel">
              <span class="d-inline-flex pr-5 text-white">Export</span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="expenseList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPageCheck"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import UserService from "@/services/UserService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import expenseService from "@/services/expenseService.js";
import { BASEURL } from "@/config/index.js";
import flatPickr from "vue-flatpickr-component";
import poChallanDetails from "@/views/apps/projects/challanDetails.vue";
import "flatpickr/dist/flatpickr.css";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    flatPickr,
    VxTour,
    DatetimePickerStartEnd,
  },
  props: ["expenseList", "CurrentPage", "totalPageCheck", "tabIndex"],

  data() {
    return {
      isActiveFirst: true,
      configTodateTimePicker: {
        maxDate: new Date(),
        minDate: null,
      },
      configFromdateTimePicker: {
        maxDate: new Date(),
        minDate: null,
      },
      excelurl: BASEURL,
      purchaseOrder: [],
      User: "Select User",
      users: [],
      users_option: [],
      projects: [],
      projects_option: [],
      categories: [],
      category_option: [],
      statuses_option: [],
      form: {
        project_id: "",
        user_id: "",
        category_id: "",
        status_id: "",
        dateFrom: "",
        dateTo: "",
      },

      statuses: [
        { status_name: "Approve", status_id: 1 },
        { status_name: "Rejected", status_id: 2 },
        { status_name: "Pending", status_id: 0 },
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      // fromDate: null,
      // toDate: null,
      // configFromdateTimePicker: {
      //   minDate: null,
      // },
      // configTodateTimePicker: {
      //   maxDate: new Date(),
      // },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
          width: 275,
          filter: true,
          // checkboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // headerCheckboxSelection: true,
        },
        {
          headerName: "User",
          field: "user_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "PO Name",
          field: "po_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "Category",
          field: "category_name",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        // {
        //   headerName: "Sub Category",
        //   field: "sub_category_name",
        //   filter: true,
        //   width: 375,
        //   //   pinned: "left"
        // },
        {
          headerName: "Amount",
          field: "formattedAmount",
          filter: true,
          width: 200,
        },
        // {
        //   headerName: "Expense Date",
        //   field: "approver_id",
        //   filter: true,
        //   width: 200,
        // },
        {
          headerName: "Status",
          field: "purchase_status",
          filter: false,
          width: 150,
          cellRenderer: function (params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            }
          },
        },
        {
          headerName: "Challan Detail",
          field: "purchase_id",
          filter: false,
          width: 155,
          cellRendererFramework: poChallanDetails,
        },
        // {
        //   headerName: "Edit",
        //   field: "",
        //   filter: false,
        //   width: 80,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        //   },
        // },
        // {
        //   headerName: "Download",
        //   field: "",
        //   filter: false,
        //   width: 150,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">save_alt</i></span>';
        //   }
        // }
        // {
        //   headerName:
        //     this.archive == "archive" ? "Unarchive" : "Archive",
        //   field: "",
        //   filter: false,
        //   width: 150,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
        //   },
        // },
      ],
      contacts: [],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    totalPo() {
      return this.expenseList.reduce(
        (acc, obj) =>
          (acc += obj.total_amount ? obj.total_amount : obj.total_amount),
        0
      );
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (this.tabIndex == 1) {
          eventBus.$emit("getPageNumber", val);
        }

        // this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    downloadExcel: function () {
      let data = [];
      for (var i = 0; i < this.expenseList.length; i++) {
        var odata = this.expenseList[i];
        var obj = {};
        obj.ProjectName = odata.project_name;
        obj.UserName = odata.user_name;
        obj.POName = odata.po_name;
        obj.CategoryName = odata.category_name;
        obj.Amount = odata.price;
        if (odata.purchase_status == 0) {
          obj.Status = "Pending";
        } else if (odata.purchase_status == 1) {
          obj.Status = "Approved";
        } else {
          obj.Status = "Rejected";
        }
        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `expenssList.xlsx`);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
