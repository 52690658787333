<template>
  <div id="dashboard-analytics">
    <vx-card class="form-title mb-6 vx-card-np" title="Select Form Type">
      <div class="vx-row mx-0">
        <div class="vx-col w-full">
          <vs-tabs>
            <vs-tab label="Create Expense" icon-pack="feather" icon="icon-box">
              <div>
                <expense-form
                  :projectList="projects"
                  :alternateOrg="alternateOrg"
                  :currencyList="currencyList"
                  :shootLocationList="shootLocationList"
                  :vendorList="vendorList"
                  :countryList="countryList"
                />
              </div>
            </vs-tab>
            <vs-tab
              label="Create Petty Cash Request"
              icon-pack="feather"
              icon="icon-box"
              v-if="isAdvanced"
            >
              <div class="tab-text">
                <div>
                  <cash-request-form
                    :projects="projects"
                    :alternateOrg="alternateOrg"
                    :currencyList="currencyList"
                    :shootLocationList="shootLocationList"
                    :vendorList="vendorList"
                    :countryList="countryList"
                  />
                </div>
              </div>
            </vs-tab>
            <vs-tab
              v-if="show_po == true"
              label="Create Purchase Order"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <purchase-order-form
                  :projectList="projects"
                  :alternateOrg="alternateOrg"
                  :currencyList="currencyList"
                  :shootLocationList="shootLocationList"
                  :vendorList="vendorList"
                  :countryList="countryList"
                />
              </div>
            </vs-tab>

            <vs-tab
              label="Create Vendor Advance Request"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <vendor-advance-request-form
                  :projectList="projects"
                  :alternateOrg="alternateOrg"
                  :currencyList="currencyList"
                  :shootLocationList="shootLocationList"
                  :vendorList="vendorList"
                  :countryList="countryList"
                />
              </div>
            </vs-tab>
            <vs-tab
              v-if="show_po == true"
              label="Create Delivery Challan"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <delivery-challan-form
                  :projectList="projects"
                  :alternateOrg="alternateOrg"
                  :currencyList="currencyList"
                  :shootLocationList="shootLocationList"
                  :vendorList="vendorList"
                />
              </div>
            </vs-tab>
            <vs-tab
              label="Create Credit Note"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <Credit-Note-Form
                  :projectList="projects"
                  :alternateOrg="alternateOrg"
                  :currencyList="currencyList"
                  :shootLocationList="shootLocationList"
                  :vendorList="vendorList"
                  :countryList="countryList"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import cashRequestForm from "@/views/apps/expenses/cashRequestForm.vue";
import purchaseOrderForm from "@/views/apps/expenses/PurchaseOrderForm.vue";
import vendorAdvanceRequestForm from "@/views/apps/expenses/vendorAdvanceRequestForm.vue";
import deliveryChallanForm from "@/views/apps/expenses/deliveryChallanForm.vue";
import expenseForm from "@/views/apps/expenses/expenseForm.vue";
import CreditNoteForm from "@/views/apps/expenses/CreditNoteForm.vue";
import projectService from "@/services/projectService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import CurrencyService from "@/services/currencyService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import ContractService from "@/services/ContractService.js";
import CountryService from "@/services/countryService.js";

export default {
  components: {
    cashRequestForm,
    purchaseOrderForm,
    deliveryChallanForm,
    expenseForm,
    CreditNoteForm,
    vendorAdvanceRequestForm,
  },
  computed: {
    isAdvanced() {
      return window.localStorage.getItem("isAdvanced") == 1;
    },
    isCreditNote() {
      return window.localStorage.getItem("isCreditNote");
    },
  },
  data() {
    return {
      show_po: window.localStorage.getItem("isPurchaseOrder"),
      tabIndex: 0,
      projects: [],
      alternateOrg: [],
      currencyList: [],
      countryList: [],
      shootLocationList: [],
      vendorList: [],
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
    };
  },
  methods: {
    getProjectsList: function() {
      this.projects = [];
      this.alternateOrg = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }

            if (data.alternateOrgNew.length > 0) {
              this.alternateOrg = data.alternateOrgNew;
            } else {
              this.alternateOrg = [];
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCurrencyList: function() {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.currencyList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getVendorList: function(e) {
      this.vendorList = [];
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.vendorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCountryList: function() {
      CountryService.getCountry()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.countryList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  beforeMount() {
    this.getProjectsList();
    this.getCurrencyList();
    this.getShootLocationList();
    this.getCountryList();
    this.getVendorList();
  },
};
</script>
