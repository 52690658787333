<template>
  <div>
    <vs-popup
      style="z-index: 99999999 !important"
      id="popmodalCrop"
      class="popmodalViewR"
      title="Crop Image"
      :active.sync="popupCropImg"
    >
      <div class="vx-row mt-5" v-if="allSelectedImages.length > 0">
        <div
          class="vx-col"
          :key="imageKey"
          v-for="(image, imageKey) in allSelectedImages"
        >
          <img
            @click="getImageToCrop(image)"
            v-if="image.dataURL"
            :src="image.dataURL"
            width="150"
            height="150"
          />
        </div>
      </div>
      <!-- SUBMIT BUTTON -->
      <div class="vx-row mt-5">
        <div class="vx-col mx-auto">
          <vue-cropper
            :img-style="{ width: '400px', height: '300px' }"
            v-if="showCropper == true"
            ref="cropper"
            :src="selectedImageForCrop.dataURL"
          >
          </vue-cropper>
          <!-- :disabled="submitStatus" -->
          <vs-button
            @click="getCropedImage"
            color="success"
            type="filled"
            class="mt-3"
            >Crop</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default Vue.extend({
  props: {
    allSelectedImages: Array,
  },
  data() {
    //:::
    return {
      popupCropImg: false,
      showCropper: false,
      selectedImageForCrop: null,
    };
  },
  components: {
    VueCropper,
    // 'vue-pdf-viewer': VuePDFViewer
  },
  watch: {},
  beforeMount() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    getImageToCrop: function (file) {
      this.selectedImageForCrop = null;
      this.showCropper = false;
      this.selectedImageForCrop = file;

      //  console.log(this.selectedImageForCrop);
      setTimeout(() => {
        this.showCropper = true;
      }, 500);
    },
    closeCropper: function () {
      this.popupCropImg = false;
    },
    dataURItoBlob(dataURI) {
      // console.log(dataURI, "jj");
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
    getCropedImage: function () {
      let blob = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.9);
      var newFile = this.dataURItoBlob(blob);
      // assign original filename
      newFile.cropped = true;
      newFile.name = this.selectedImageForCrop.name;

      let payload = {
        fileToRemove: this.selectedImageForCrop,
        fileToAdd: newFile,
      };

      eventBus.$emit("UploadCropedImage", payload);
      this.popupCropImg = false;
      // this.vremoved(this.selectedImageForCrop);
      // this.$refs.myVueDropzoneFile.removeFile(this.selectedImageForCrop);
      // this.$refs.myVueDropzoneFile.addFile(newFile);
    },
  },
  mounted() {
    if (this.allSelectedImages.length > 0) {
      this.popupCropImg = true;
    }
  },
});
</script>
<style></style>
