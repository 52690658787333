<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name:
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- :pagination="true"
          :paginationPageSize="paginationPageSize"
    :suppressPaginationPanel="true"-->
    <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-end items-center">
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <h5 class="mr-5">
              Total Amount:
              <span class="text-primary font-bold">{{
                totalExpense.toLocaleString("en-IN")
              }}</span>
            </h5>
            <vs-input
              class="mb-4 mr-0 sm:mr-4 w-full sm:w-auto"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <!-- <vx-tooltip text="Export Excel"> -->
            <!-- <a
                :href="excelurl+`/v1/expenses/excel/download?project_id=${this.projectID}&approver_id=1search=${searchQuery}`"
                target="_blank"
              >

            </a>-->
            <!-- </vx-tooltip> -->

            <vs-button
              class="px-4 mb-4 w-full sm:w-auto"
              @click.native="downloadExcel"
            >
              <span class="d-inline-flex pr-5 text-white">Export </span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="expenseList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPageCheck"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div v-if="showimageModal == true">
      <image-pop-up :imagePopParamsData="imagePopParamsData"></image-pop-up>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import UserService from "@/services/UserService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import expenseService from "@/services/expenseService.js";
import moveToBudget from "./moveToBudget.vue";
import { BASEURL } from "@/config/index.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import expenseDetails from "../projects/expenseDetails.vue";
import imagePopUp from "../projects/imagePopUp.vue";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    flatPickr,
    VxTour,
    DatetimePickerStartEnd,
    imagePopUp,
  },
  props: ["expenseList", "CurrentPage", "totalPageCheck", "tabIndex", "allExp"],

  data() {
    return {
      showPoOrderModal: false,
      showPoOrder1Modal: false,
      editPoParamsData: null,
      editPo1ParamsData: null,
      imagePopParamsData: null,
      expDetailsParamsData: null,
      showExpDetailsModal: false,
      showimageModal: false,
      isActiveFirst: true,
      configTodateTimePicker: {
        maxDate: new Date(),
        minDate: "",
      },
      configFromdateTimePicker: {
        maxDate: new Date(),
        minDate: "",
      },
      excelurl: BASEURL,
      // expense: this.expenseList,
      User: "Select User",
      users: [],
      users_option: [],
      projects: [],
      projects_option: [],
      categories: [],
      category_option: [],
      statuses_option: [],
      form: {
        project_id: "",
        user_id: "",
        category_id: "",
        status_id: "",
        dateFrom: "",
        dateTo: "",
      },
      statuses: [
        { status_name: "Approve", status_id: 1 },
        { status_name: "Rejected", status_id: 2 },
        { status_name: "Pending", status_id: 0 },
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      // fromDate: null,
      // toDate: null,
      // configFromdateTimePicker: {
      //   minDate: null,
      // },
      // configTodateTimePicker: {
      //   maxDate: new Date(),
      // },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
          width: 275,
          filter: true,
          // checkboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // headerCheckboxSelection: true,
        },
        {
          headerName: "User",
          field: "user_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "Expense Name",
          field: "po_name",
          filter: true,
          width: 275,
        },
        // {
        //   headerName: "VHC Code",
        //   field: "VHC_code",
        //   filter: true,
        //   width: 275,
        //   //   pinned: "left"
        // },
        {
          headerName: "Subcategory",
          field: "category_name",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Amount",
          field: "total",
          filter: true,
          width: 200,
        },
        // {
        //   headerName: "Payment Due Date",
        //   field: "payment_due_date",
        //   filter: true,
        //   width: 200,
        // },
        {
          headerName: "Status",
          field: "purchase_status",
          filter: false,
          sortable: false,
          width: 150,
          cellRenderer: function(params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            }
          },
        },
        // {
        //   headerName: "Details",
        //   field: "",
        //   filter: true,
        //   width: 150,
        //   cellRendererFramework: expenseDetails,
        // },
        {
          headerName: "Multiple Images",
          field: "purchase_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRenderer: function(params) {
            return params.data.hasOwnProperty("purchase_image") &&
              params.data.purchase_image != "-"
              ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
              : '    <span class="text-center">No Images</span>';
          },
          //  cellRendererFramework: imagePopUp,
        },
        // {
        //   headerName: "Move to Budget",
        //   field: "purchase_id",
        //   filter: false,
        //   width: 200,
        //   minWidth: 200,
        //   sortable: false,
        //   cellRendererFramework: moveToBudget
        // }
        // {
        //   headerName: "Edit",
        //   field: "",
        //   filter: false,
        //   width: 80,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        //   },
        // },
        // {
        //   headerName: "Download",
        //   field: "",
        //   filter: false,
        //   width: 150,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">save_alt</i></span>';
        //   }
        // }
        // {
        //   headerName:
        //     this.archive == "archive" ? "Unarchive" : "Archive",
        //   field: "",
        //   filter: false,
        //   width: 150,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
        //   },
        // },
      ],
      contacts: [],
    };
  },
  watch: {
    // This would be called anytime the value of title changes

    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },

  //  beforeMount() {
  //   eventBus.$on("refreshexpTable", e => {
  //     this.getEmployeeList();
  //   });
  // },

  computed: {
    totalExpense() {
      return this.expenseList.reduce(
        (acc, obj) =>
          (acc += obj.total_amount ? obj.total_amount : obj.total_amount),
        0
      );
    },
    expenseFilter() {
      return this.$store.getters.expenseFilter;
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (this.tabIndex == 2) {
          eventBus.$emit("getPageNumber", val);
        }

        // this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onCellClicked(params) {
      console.log(params);

      if (
        params.colDef.field == "purchase_image" &&
        params.data.purchase_image &&
        params.data.purchase_image != "-"
      ) {
        this.showimageModal = false;
        this.imagePopParamsData = params;
        setTimeout(() => {
          this.showimageModal = true;
        }, 300);
      }
    },
    downloadExcel: function() {
      let data = [];
      for (var i = 0; i < this.allExp.length; i++) {
        var odata = this.allExp[i];

        var obj = {};
        obj.ProjectName = odata.project_name;
        obj.UserName = odata.user_name;
        obj.ExpenseName = odata.po_name;
        // obj.VHCCode = odata.VHC_code;
        // obj.PaymentDueDate = odata.payment_due_date;

        obj.CategoryName = odata.category_name;
        obj.Amount = odata.total;

        if (odata.purchase_status == 0) {
          obj.Status = "Pending";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = "NA";
        } else if (odata.purchase_status == 1) {
          obj.Status = "Approved";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = odata.approvedDate;
        } else {
          obj.Status = "Rejected";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = odata.approvedDate;
        }

        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `expenssList.xlsx`);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
