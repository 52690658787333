import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getExpense(payload, filters) {
    // return Api().post(`/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id}`, payload);
    if (filters && filters.hasOwnProperty("page")) {
      return Api().post(
        `/v2/purchase/expense/getExpenseByFilters?page_no=${filters.page
        }&organization_id=${organization_id()}&type=${payload.type}`,
        payload
      );
    } else {
      return Api().post(
        `/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id()}&type=0`,
        payload
      );
    }
  },
  getAllExpense(payload) {
    let permisProj = payload.permissionProject ? payload.permissionProject : "";
    return Api().get(
      `/v2/purchase?organization_id=${organization_id()}&owner=0&purchaseStatus=${payload.purchaseStatus
      }&permissionProject=${permisProj}`
    );
  },
  checkInvoice(vendor_id, invoicenumber, project_id) {
    // let permisProj = payload.permissionProject ? payload.permissionProject : "";
    return Api().get(
      `/v2/purchase/checkinvoice/${vendor_id}/${invoicenumber}/${project_id}?organization_id=${organization_id()}`
    );
  },
  getSingleExpense(purchase_id) {
    return Api().get(
      `/v2/purchase/${purchase_id}?organization_id=${organization_id()}`
    );
  },
  getExpenseByFilter(payload, filters) {
    // if (payload && payload.hasOwnProperty('page_no')) {
    // return Api().post(`/v2/purchase/expense/getExpenseByFilters?page_no=${payload.page_no}&organization_id=${organization_id()}&type=${payload.type}`, payload);
    // } else {
    return Api().post(
      `/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id()}`,
      payload
    );
    // }
  },
  addExpense(payload, filters) {
    return Api().post(
      `/v2/purchase?organization_id=${organization_id()}`,
      payload
    );
  },

  addCreditNote(payload, filters) {
    return Api().post(
      `/v2/creditNote?organization_id=${organization_id()}`,
      payload
    );
  },
  showCreditExpenseList(project_id) {
    return Api().get(
      `/v2/purchase/creditNote/${project_id}?organization_id=${organization_id()}`
    );
  },

  // Vendor Advance Request Routes

  addVendorAdvanceRequest(payload, filters) {
    return Api().post(
      `/v2/vendorAdvanceRequest?organization_id=${organization_id()}`,
      payload
    );
  },
  getAllVendorRequestOrderByProject(id, payload) {
    let url = `/v2/vendorAdvanceRequest/project/${id}?isDashboard=1&organization_id=${organization_id()}&type=${payload.type
      }`;
    if (payload.hasOwnProperty("page_no")) {
      url += `&page_no=${payload.page_no}`;
    }
    if (payload.hasOwnProperty("tallySync")) {
      if (payload.tallySync == "All") {
      } else if (payload.tallySync == "Approved") {
        url += `&purchaseStatus=1`;
      } else if (payload.tallySync == "Synced") {
        url += `&tallyType=0`;
      } else if (payload.tallySync == "Unsynced") {
        url += `&tallyType=1`;
      }
    }
    return Api().get(url);
  },

  getUserExpenses(payload) {
    return Api().get(`/v2/purchase/other?organization_id=${organization_id()}${payload.project_id ? '&project_id=' + payload.project_id : ''}&purchaseStatus=${payload.purchaseStatus}&search=${payload.search || ''}&limit=${payload.limit || ''}&page_no=${payload.page || ''}`);
  },

  getMyExpenses(payload) {
    return Api().get(`/v2/purchase?organization_id=${organization_id()}${payload.project_id ? '&project_id=' + payload.project_id : ''}&purchaseStatus=${payload.purchaseStatus}&search=${payload.search || ''}&limit=${payload.limit || ''}&page_no=${payload.page || ''}`);
  },

  approveMultipleExpenses(payload) {
    return Api().post("/v2/purchase/approveMultipleExpenses", payload)
  }
};
