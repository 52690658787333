<template>
  <div>
    <span
      v-if="hideIcon == false"
      class="text-center table-edit-icon"
      @click="showModal"
    >
      <i class="text-center material-icons">edit</i>
    </span>

    <div>
      <vs-popup
        style="z-index: 999999 !important"
        :id="'popmodal' + purchase_id"
        title="Expense"
        :active.sync="popupActiveImages"
      >
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            :key="imageKey"
            v-for="(image, imageKey) in images"
          >
            <div
              class="swiper-zoom-container"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <img
                style="height: 60vh; width: auto"
                :src="image + '?token=' + tempToken"
                alt="banner"
              />
            </div>

            <div class="row col-12" v-else>
              <vs-button
                class="px-4 text-center"
                @click.native="downloadPdf(image, tempToken)"
                style="margin: auto"
              >
                <span class="d-inline-flex pr-5 text-white"
                  >Click here to preview</span
                >
                <img
                  src="@/assets/images/pdf-icon.png"
                  v-if="image.split('.').slice(-1)[0] == 'pdf'"
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
                <img
                  src="@/assets/images/excelLogo.png"
                  v-else
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
              </vs-button>
            </div>
            <div
              class="mx-auto text-center my-2"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <button
                @click="downloadPdf(image)"
                class="vs-component vs-button vs-button-primary vs-button-filled"
              >
                Download
              </button>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </vs-popup>

      <vs-popup
        class="popmodalViewR"
        :id="'popmodalViewR' + params.data.purchase_id"
        title="Expense Logs"
        :active.sync="popupActive0"
      >
        <div
          class="vx-col w-full"
          v-for="(data, index) in expenses_datas"
          :key="index"
        >
          <div class="mx-0 row mb-4">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Expenses Created By:</p>
                <div class>
                  <h5>{{ expenses_create_by }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <vs-button @click.prevent.native="showImages(data.purchase_image)"
                >View Images</vs-button
              >
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">VHC Code:</p>
                <div class>
                  <h5>{{ data.VHC_code }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Category:</p>
                <div class>
                  <h5>{{ data.category }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Sub Category:</p>
                <div class>
                  <h5>{{ data.sub_category }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Invoice Number:</p>
                <div class>
                  <h5>{{ data.invoice_number }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Invoice Date:</p>
                <div class>
                  <h5>{{ data.invoice_date }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Payment Due Date:</p>
                <div class>
                  <h5>{{ data.payment_due_date }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Amount:</p>
                <div class>
                  <h5>{{ data.price }}</h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Tax:</p>
                <div class>
                  <h5>{{ data.gst_amount }}</h5>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Total Amount:</p>
                <div class>
                  <h5>{{ data.total_amount }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-4">
            <div class="col-4">
              <div>
                <p class="text-muted small mb-1">Descrioption:</p>
                <div class>
                  <h5>{{ data.purchase_description }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-12">
              <div class="vx-col w-full">
                <p
                  class="text-muted small mb-1"
                  v-for="(data1, index1) in data.purchase_mapping"
                  :key="index1"
                >
                  <span class="outlevel"
                    >Level {{ data1.level }}:
                    <span class="inlevel">{{ data1.description }}</span></span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Reject"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>Reason For rejection</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea
                counter="240"
                v-model="rejectionComment"
                class="mb-4"
                rows="4"
              />
              <vs-button
                color="success"
                type="filled"
                @click="onSubmit()"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Reject"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>Reason For rejection</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea
                v-model="rejectionComment"
                class="mb-4"
                rows="4"
                counter="240"
              />
              <vs-button
                color="success"
                type="filled"
                @click="onSubmit()"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept"
        :active.sync="popupActive2"
      >
        <div class="text-center">
          <h5>Reason For Accepting</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="acceptComment" class="mb-4" rows="4" />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept"
        :active.sync="popupActive3"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Accepting
          </h5>
          <h5 v-else>Reason For Accept</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea
                counter="240"
                v-model="acceptComment"
                class="mb-4"
                rows="4"
              />

              <vs-button
                color="success"
                type="filled"
                @click="onSubmitPO()"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr1' + params.data.purchase_id"
        title="Add Document Update Approved Expenses"
        :active.sync="popupActive44"
      >
        <div class="text-center">
          <h5>Reason</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea
                counter="240"
                v-model="acceptCommentUpload"
                class="mb-4"
                rows="4"
              />

              <vs-button
                color="success"
                type="filled"
                @click="submitForm('resubmit=2')"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :id="'popmodalr' + params.data.purchase_id"
        title="Accept"
        :active.sync="popupActive4"
      >
        <div class="text-center">
          <h5 v-if="String(params.data.approver_id) == '0'">
            Reason For Resubmitting
          </h5>
          <h5 v-else>Reason For Resubmitting</h5>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea
                counter="240"
                v-model="acceptComment"
                class="mb-4"
                rows="4"
              />

              <vs-button
                color="success"
                type="filled"
                @click="submitForm('resubmit=1')"
                class="mr-3"
              >
                Submit
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        fullscreen
        :id="'popmodale' + params.data.purchase_id"
        :title="
          String(params.data.approver_id) == '0' &&
          String(params.data.is_var) == '1'
            ? `Edit VAR`
            : String(params.data.approver_id) == '0' &&
              String(params.data.is_var) == '0'
            ? `Edit Expense`
            : String(params.data.is_var) == '2'
            ? `Edit Credit Note`
            : `Edit Petty Cash Request`
        "
        class="po-popup"
        :active.sync="popupActive"
        @close="() => this.$emit('closeEdit', false)"
      >
        <div class="row mx-0 pt-5 h-100">
          <div class="col-md-6">
            <div class="mb-5 p-3">
              <ul
                class="level-list mb-5"
                v-if="is_paid != 1 && mod_user.length > 0"
              >
                <li class="pb-2">
                  <h5>
                    <img
                      src="@/assets/images/icons/moderator.svg"
                      height="20"
                      width="20"
                      style="position: relative; top: -3px"
                    />
                    Production Accountant
                  </h5>

                  <div v-for="(mod_user, index) in mod_user" :key="index">
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="mod_user.purchase_map_status == 0"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-pending.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            Approval pending from&nbsp;
                            <span class="font-weight-bold">
                              {{ mod_user.user_name }}
                            </span>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                  (LoginUserId == mod_user.user_id ||
                                    adminManagerArray.filter(
                                      (u) => u == LoginUserId
                                    ).length > 0)
                              "
                              v-on:click="approvePO(mod_user, 'moderator')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                UserRole != 'Admin' &&
                                  (LoginUserId == mod_user.user_id ||
                                    adminManagerArray.filter(
                                      (u) => u == LoginUserId
                                    ).length > 0)
                              "
                              v-on:click="rejectPO(mod_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {{
                      mod_user.purchase_map_status +
                        " mod== " +
                        mod_user.purchase_map_status1
                    }}
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="
                        mod_user.purchase_map_status == 1 ||
                          mod_user.purchase_map_status1 == 6
                      "
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approved-tick.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <div>{{ mod_user.description }}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex align-items-center py-2"
                      v-if="mod_user.purchase_map_status == 2"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-rejected.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div>{{ mod_user.description }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="mod_user.purchase_map_status == 3"
                        class="d-flex py-2"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="mod_user.user_id != mod_user.puserid"
                          />
                        </div>
                        {{
                          mod_user.user_id == mod_user.puserid
                            ? "Created By "
                            : "Not Yet Reached "
                        }}
                        &nbsp;
                        <span class="font-weight-bold">
                          {{ mod_user.user_name }}
                        </span>
                      </div>
                    </div>
                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="mod_user.purchase_map_status == 4"
                        class="d-flex py-2"
                      >
                        {{ mod_user.approvedBy }} for&nbsp;
                        <span class="font-weight-bold">
                          {{ mod_user.user_name }}!
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- USER -->
              <ul
                class="level-list mb-5"
                v-if="is_paid != 1 || (is_paid == 1 && admin_user.length == 0)"
              >
                <li v-for="(level, levelIndex) in levels" :key="levelIndex">
                  <h5>Level {{ level.levelName }}</h5>
                  <div class="col-11 ml-auto px-0">
                    <div
                      v-for="(singleUser, userIndex) in level.level"
                      :key="'user' + userIndex"
                      class="d-flex align-items-center py-2 border-bottom"
                    >
                      <div
                        v-if="
                          singleUser.purchase_map_status == 1 ||
                            singleUser.purchase_map_status == 6
                        "
                        class="d-flex align-items-center"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approved-tick.svg"
                            height="30"
                            width="30"
                          />
                        </div>

                        <div>{{ singleUser.description }}</div>
                      </div>

                      <!-- REJECTED USER -->
                      <div
                        v-if="singleUser.purchase_map_status == 2"
                        class="d-flex align-items-center"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-rejected.svg"
                            height="30"
                            width="30"
                          />
                        </div>

                        <div>{{ singleUser.description }}</div>
                      </div>

                      <!-- PENDING USER -->
                      <div
                        v-if="singleUser.purchase_map_status == 0"
                        class="d-flex align-items-center"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                          />
                        </div>
                        <span>
                          {{ singleUser.description }}
                        </span>
                        <div
                          v-if="
                            UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                adminManagerArray.filter(
                                  (u) => u == LoginUserId
                                ).length > 0 ||
                                (singleUser.user_level > userLevelLogin &&
                                  UserRole.includes('User')))
                          "
                          v-on:click="approvePO(singleUser, 'user')"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            class="cursor-pointer text-success"
                            svgClasses="h-8 w-8"
                          />
                        </div>
                        <div
                          v-if="
                            UserRole != 'Admin' &&
                              (LoginUserId == singleUser.user_id ||
                                adminManagerArray.filter(
                                  (u) => u == LoginUserId
                                ).length > 0)
                          "
                          v-on:click="rejectPO(singleUser)"
                          class="ml-2"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            class="cursor-pointer text-danger"
                            svgClasses="h-8 w-8"
                          />
                        </div>
                      </div>
                      <!-- NOT YET REACHED TO USER -->
                      <div
                        v-if="singleUser.purchase_map_status == 3"
                        class="d-flex"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="singleUser.user_id != singleUser.puserid"
                          />
                        </div>
                        {{
                          singleUser.user_id == singleUser.puserid
                            ? "Created By "
                            : "Not Yet Reached "
                        }}&nbsp;
                        <span class="font-weight-bold">
                          {{ singleUser.user_name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- ADMIN -->
              <ul
                class="level-list mb-5"
                v-if="is_paid == 1 && admin_user.length > 0"
              >
                <li class="pb-2">
                  <h5>
                    <img
                      src="@/assets/images/icons/moderator.svg"
                      height="20"
                      width="20"
                      style="position: relative; top: -3px"
                    />
                    Admin Manager
                  </h5>

                  <!-- PENDING FROM ADMIN -->
                  <div v-for="(admin_user, index1) in admin_user" :key="index1">
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="admin_user.purchase_map_status == 0"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-pending.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div class="d-flex align-items-center">
                            Approval pending from&nbsp;
                            <span class="font-weight-bold">
                              {{ admin_user.user_name }}
                            </span>
                            <div
                              v-if="
                                adminManagerArray.filter(
                                  (u) => u == LoginUserId
                                ).length > 0 ||
                                  LoginUserId == admin_user.user_id
                              "
                              v-on:click="approvePO(admin_user, 'admin')"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="cursor-pointer text-success"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                            <div
                              v-if="
                                adminManagerArray.filter(
                                  (u) => u == LoginUserId
                                ).length > 0 ||
                                  LoginUserId == admin_user.user_id
                              "
                              v-on:click="rejectPO(admin_user)"
                              class="ml-2"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="cursor-pointer text-danger"
                                svgClasses="h-8 w-8"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- APPROVED ADMIN -->

                    <div
                      class="d-flex align-items-center py-2"
                      v-if="
                        admin_user.purchase_map_status == 1 ||
                          admin_user.purchase_map_status1 == 6
                      "
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approved-tick.svg"
                              height="30"
                              width="30"
                            />
                          </div>

                          <div>{{ admin_user.description }}</div>
                        </div>
                      </div>
                    </div>

                    <!-- REJECTED BY ADMIN -->
                    <div
                      class="d-flex align-items-center py-2"
                      v-if="admin_user.purchase_map_status == 2"
                    >
                      <div class="col-11 ml-auto px-0">
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <img
                              src="@/assets/images/icons/approval-rejected.svg"
                              height="30"
                              width="30"
                            />
                          </div>
                          <div>{{ admin_user.description }}</div>
                        </div>
                      </div>
                    </div>

                    <!-- NOT YET REACHED TO ADMIN -->
                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="admin_user.purchase_map_status == 3"
                        class="d-flex py-2"
                      >
                        <div class="mr-3">
                          <img
                            src="@/assets/images/icons/approval-pending.svg"
                            height="30"
                            width="30"
                            v-if="admin_user.user_id != admin_user.puserid"
                          />
                        </div>
                        {{
                          admin_user.user_id == admin_user.puserid
                            ? "Created By "
                            : "Not Yet Reached "
                        }}&nbsp;
                        <span class="font-weight-bold">
                          {{ admin_user.user_name }}
                        </span>
                      </div>
                    </div>

                    <div class="col-11 ml-auto px-0">
                      <div
                        v-if="admin_user.purchase_map_status == 4"
                        class="d-flex py-2"
                      >
                        {{ admin_user.approvedBy }} for&nbsp;
                        <span class="font-weight-bold">
                          {{ admin_user.user_name }}!
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div v-if="purchaseData.is_verified == 1">
              <h6>Verified By : {{ user_verifier_name }}<br /></h6>
              <h6>Verified Date : {{ purchase_verified_date }}<br /></h6>
              <h6>Comment : {{ user_verifier_reason }}<br /></h6>
            </div>
          </div>

          <div class="col-md-6">
            <form ref="testingRef">
              <FocusLock>
                <div class="vx-row border-left">
                  <div class="vx-col w-full">
                    <h2
                      class="text-center mb-base"
                      v-html="
                        String(params.data.approver_id) == '0' &&
                        String(params.data.is_var) == '0'
                          ? `EDIT EXPENSE`
                          : String(params.data.approver_id) == '0' &&
                            String(params.data.is_var) == '2'
                          ? `EDIT CREDIT NOTE`
                          : String(params.data.approver_id) == '0' &&
                            String(params.data.is_var) == '1'
                          ? `EDIT VAR`
                          : String(params.data.is_var) == '3'
                          ? `EDIT PETTY CASH`
                          : purchaseData.request_type == 'CASH'
                          ? 'EDIT CASH REQUEST'
                          : `EDIT PURCHASE ORDER`
                      "
                    />

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full  text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5
                          class="mb-0"
                          v-html="
                            String(params.data.approver_id) == '0'
                              ? `Expense Name`
                              : `PO Name`
                          "
                        />
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            name="PO/Expense Name"
                            v-model="purchaseData.po_name"
                            class="w-full"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">User</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            name="User Name"
                            v-model="purchaseData.user_name"
                            class="w-full"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-6" v-if="purchaseData.is_var == 3">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0"></h5>
                      </div>
                      <!-- <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <span style="color: red">
                            Approved Balance PCR Amount :
                            {{ user_pcr_balance }} INR
                          </span>
                        </div>
                      </div> -->
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Project</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <multiselect
                          v-model="project_value"
                          track-by="project_id"
                          label="project_name"
                          placeholder="Project Name"
                          @input="
                            ({ project_id }) => (this.project_id = project_id)
                          "
                          :options="allProjects"
                          name="Project Name"
                          :searchable="true"
                          :allow-empty="false"
                          open-direction="bottom"
                          deselect-label="Can't remove this value"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Created On</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vs-input
                          disabled
                          type="text"
                          v-model="purchaseData.created_date"
                          class="w-full"
                        />
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">
                          {{
                            purchaseData.is_var == "2"
                              ? "CN Code"
                              : purchaseData.is_var == "3"
                              ? "PCR Code"
                              : "VHC Code"
                          }}
                        </h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled
                            name="VHC Code"
                            v-model="purchaseData.VHC_code"
                            class="w-full"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Country</h5>
                      </div>

                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            :options="countries"
                            v-model="country_value"
                            v-validate="'required'"
                            track-by="country_id"
                            label="country_name"
                            placeholder="Country Name"
                            @input="
                              ({ country_id }) => (this.country_id = country_id)
                            "
                            name="Country Name"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            :disabled="
                              purchase_status == 1 || showCont == false
                            "
                            @select="onSelectCountry($event)"
                          />
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('Country Name')"
                            >
                              {{ errors.first("Country Name") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-1"
                      v-if="purchaseData.request_type != 'CASH'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">City</h5>
                      </div>

                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            :disabled="cityDisabled || purchase_status == 1"
                            :options="cities"
                            v-model="city_value"
                            track-by="city_id"
                            label="city_name"
                            placeholder="City Name"
                            name="City Name"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                          />
                          <!-- :disabled="purchase_status == 1" -->
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('City Name')"
                            >
                              {{ errors.first("City Name") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Category</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            v-model="categoryValue"
                            track-by="category_id"
                            label="category_name"
                            :options="categories"
                            name="Category Name"
                            placeholder="Select Category"
                            @select="onSuggestSelect"
                            :searchable="true"
                            :allow-empty="false"
                            deselect-label="Can't remove this value"
                            :disabled="
                              purchase_status == 1 ||
                                purchaseData.is_var == '2' ||
                                (purchaseData.is_var == '2' &&
                                  purchase_status == 2) ||
                                showCat == false
                            "
                          />
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('Category Name')"
                            >
                              {{ errors.first("Category Name") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-1">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Subcategory</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            v-model="subcategoryValue"
                            track-by="category_id"
                            @input="
                              ({ category_id }) =>
                                (this.form.category_id = category_id)
                            "
                            label="category_name"
                            :options="subcategories"
                            name="subcategory Name"
                            placeholder="Select Subcategory"
                            :searchable="true"
                            :allow-empty="false"
                            open-direction="bottom"
                            deselect-label="Can't remove this value"
                            :disabled="
                              purchase_status == 1 ||
                                purchaseData.is_var == '2' ||
                                (purchaseData.is_var == '2' &&
                                  purchase_status == 2) ||
                                showSubCat == false
                            "
                          />
                          <p class="error-msg">
                            <span
                              class="text-danger text-xs error-msg"
                              v-show="errors.has('subcategory Name')"
                            >
                              {{ errors.first("subcategory Name") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-6"
                      v-if="
                        purchaseData.request_type != 'CASH' &&
                          purchaseData.is_var != '3'
                      "
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Invoice Number</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            name="invoice_number"
                            type="text"
                            v-model="purchaseData.invoice_number"
                            class="w-full"
                            :disabled="purchase_status == 1"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('invoice_number')"
                          >
                            {{ errors.first("invoice_number") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-6"
                      v-if="
                        purchaseData.request_type != 'CASH' &&
                          purchaseData.is_var != '3'
                      "
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Invoice Date</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <datepicker
                            placeholder="Invoice Date"
                            v-model="purchaseData.invoice_date"
                            format="dd-MM-yyyy"
                            :disabled="purchase_status == 1"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Invoice Date')"
                          >
                            {{ errors.first("Invoice Date") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-6"
                      v-if="purchaseData.request_type != 'CASH'"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Payment Due Date</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <datepicker
                            placeholder="Payment Due Date"
                            v-model="purchaseData.payment_due_date"
                            format="dd-MM-yyyy"
                            :disabled="
                              purchase_status == 1 || purchaseData.is_var == '2'
                            "
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Payment Due Date')"
                          >
                            {{ errors.first("Payment Due Date") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            v-validate="'required'"
                            name="Amount"
                            type="number"
                            v-model.number="purchaseData.total"
                            class="w-full"
                            @change="calcTotalAmount"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Amount')"
                          >
                            {{ errors.first("Amount") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-3"
                      v-if="
                        purchaseData.request_type != 'CASH' &&
                          purchaseData.is_var != '3'
                      "
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Tax</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            label="gst_name"
                            class="w-full"
                            track-by="gst_id"
                            v-model="purchaseData.gst_id"
                            name="gst"
                            @select="gstCalculate"
                            :options="gstList"
                            :searchable="false"
                            :allow-empty="false"
                            open-direction="bottom"
                            placeholder="Select Option"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 1"
                          />
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Priority')"
                            >
                              {{ errors.first("Priority") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-3"
                      v-if="
                        purchaseData.request_type != 'CASH' &&
                          purchaseData.is_var != '3'
                      "
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">TAX Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vs-input
                          type="number"
                          name="GST"
                          v-model="purchaseData.gst_amount"
                          class="w-full"
                          placeholder=""
                          :disabled="
                            purchaseData.total <= 1 ||
                              purchase_status == 1 ||
                              (purchaseData.gst_id &&
                                purchaseData.gst_id.gst_name != 'Manual Amount')
                          "
                          @change="calcTotalAmount"
                        />
                        <p class="error-msg text-sm">
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('GST Amount')"
                          >
                            {{ errors.first("GST Amount") }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="vx-row mb-3" v-if="purchaseData.is_var != '2'">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">TDS</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <multiselect
                            label="tds_name"
                            class="w-full"
                            track-by="tds_id"
                            v-model="purchaseData.tds_id"
                            name="tds"
                            @select="tdsCalculate"
                            :options="tdsList"
                            :searchable="false"
                            :allow-empty="false"
                            open-direction="bottom"
                            placeholder="Select Option"
                            deselect-label="Can't remove this value"
                            :disabled="purchase_status == 1"
                          />
                          <p class="error-msg text-sm">
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Priority')"
                            >
                              {{ errors.first("Priority") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-3" v-if="purchaseData.is_var != 2">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">TDS Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vs-input
                          type="number"
                          name="TDS"
                          v-model="purchaseData.tds_amount"
                          class="w-full"
                          placeholder=""
                          :disabled="
                            purchaseData.total <= 1 ||
                              purchase_status == 1 ||
                              (purchaseData.tds_id &&
                                purchaseData.tds_id.tds_name != 'Manual Amount')
                          "
                          @change="calcTotalAmount"
                        />
                        <p class="error-msg text-sm">
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('TDS Amount')"
                          >
                            {{ errors.first("TDS Amount") }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <!-- v-if="purchaseData.request_type != 'CASH'" -->
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Total Amount</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <div class>
                          <vs-input
                            disabled="true"
                            name="Amount"
                            type="number"
                            v-model.number="purchaseData.total_amount"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Total Amount')"
                          >
                            {{ errors.first("Total Amount") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="vx-row mb-3"
                      v-if="
                        purchaseData.request_type != 'CASH' &&
                          purchaseData.is_var != '2'
                      "
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h5 class="mb-0">Set Name</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <multiselect
                          label="location_name"
                          class="w-full"
                          track-by="location_id"
                          v-model="locationValue"
                          name="location"
                          :options="shootLocationList"
                          :searchable="false"
                          :allow-empty="false"
                          open-direction="bottom"
                          placeholder="Select Option"
                          deselect-label="Can't remove this value"
                          :disabled="
                            purchase_status == 1 || purchase_status == 2
                          "
                        />
                        <p class="error-msg text-sm">
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('location')"
                          >
                            {{ errors.first("location") }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right"
                      >
                        <h5 class="mb-0">Description</h5>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vs-textarea
                          :disabled="purchase_status == 1"
                          v-model="purchaseData.purchase_description"
                          class="mb-0"
                          rows="4"
                        />
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('Description')"
                        >
                          {{ errors.first("Description") }}
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="
                        (purchase_status == 1 || purchase_status == 2) &&
                          purchaseData.is_var != '3'
                      "
                      class="vx-row mb-6 doc-upload"
                    >
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center"
                      >
                        <h6>Upload Document</h6>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full">
                        <vue-dropzone
                          @vdropzone-removed-file="vremoved"
                          @vdropzone-error-multiple="vdropzoneError"
                          @vdropzone-complete-multiple="vdropzoneComplete"
                          @vdropzone-added-files="vdropzoneFilesAdded"
                          @vdropzone-success-multiple="vdropzoneSuccess"
                          ref="myVueDropzoneFile"
                          id="dropzone"
                          class="rounded"
                          :options="dropzoneOptionsFiles"
                        />

                        <vs-button
                          v-if="fileUpload.deleteButtonStatus"
                          type="filled"
                          @click.prevent="clearFile()"
                          class="mt-2 feather icon-trash"
                        />
                        <vs-button
                          v-if="fileUpload.deleteButtonStatus"
                          type="filled"
                          @click.prevent="openCropper()"
                          class="ml-4 mt-2"
                        >
                          Crop Images
                        </vs-button>
                      </div>
                    </div>

                    <div class="vx-row mb-6 doc-upload">
                      <div
                        class="vx-col sm:w-1/4 w-full text-left mb-1 sm:mb-0 sm:text-right align-self-center mt-4"
                      >
                        <h6>Uploaded Documents</h6>
                      </div>
                      <div class="vx-col sm:w-3/4 w-full mt-4">
                        <b-list-group
                          v-for="(img, index) in uploadedDocumentArray"
                          :key="index + 'approved'"
                          style="max-width: 500px"
                        >
                          <b-list-group-item
                            v-if="img && img != '-'"
                            class="d-flex align-items-center"
                          >
                            <div
                              v-if="
                                extensions.indexOf(
                                  img.split('.').slice(-1)[0]
                                ) >= 0
                              "
                            >
                              <img
                                variant="info"
                                :src="img + '?token=' + tempToken"
                                class="mr-10 h-6"
                              />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/images/pdf-icon.png"
                                v-if="img.split('.').slice(-1)[0] == 'pdf'"
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                              <img
                                src="@/assets/images/docx_icon.png"
                                v-else-if="
                                  img.split('.').slice(-1)[0] == 'docx' ||
                                    img.split('.').slice(-1)[0] == 'doc'
                                "
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                              <img
                                src="@/assets/images/excelLogo.png"
                                v-else
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                            </div>
                            <a
                              class="mr-10 cursor-pointer"
                              @click="downloadPdf(img)"
                            >
                              <u>view</u>
                            </a>
                            <a
                              v-if="
                                disableUserLevelEdit == false ||
                                  (showresubmit &&
                                    String(params.data.approver_id) == '0' &&
                                    UserRole != 'Admin')
                              "
                              class="mr-auto cursor-pointer"
                              @click="moveToIgnore(index, img)"
                            >
                              <u>move to ignore</u>
                            </a>
                          </b-list-group-item>
                        </b-list-group>
                        <b-list-group
                          v-for="(img, index) in ignoredDocArray"
                          :key="index + 'ignored'"
                          style="max-width: 500px"
                        >
                          <b-list-group-item
                            v-if="img && img != '-'"
                            class="d-flex align-items-center"
                          >
                            <b-badge class="mr-3" variant="danger">
                              ignored
                            </b-badge>
                            <div
                              v-if="
                                extensions.indexOf(
                                  img.split('.').slice(-1)[0]
                                ) >= 0
                              "
                            >
                              <img
                                variant="info"
                                :src="img + '?token=' + tempToken"
                                class="mr-10"
                              />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/images/pdf-icon.png"
                                v-if="img.split('.').slice(-1)[0] == 'pdf'"
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                              <img
                                src="@/assets/images/docx_icon.png"
                                v-else-if="
                                  img.split('.').slice(-1)[0] == 'docx' ||
                                    img.split('.').slice(-1)[0] == 'doc'
                                "
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                              <img
                                src="@/assets/images/excelLogo.png"
                                v-else
                                class="d-inline-flex mr-10"
                                height="40"
                                width="40"
                              />
                            </div>
                            <a
                              class="mr-10 cursor-pointer"
                              @click="downloadPdf(img)"
                            >
                              <u>view</u>
                            </a>
                            <a
                              v-if="
                                disableUserLevelEdit == false ||
                                  (showresubmit &&
                                    String(params.data.approver_id) == '0' &&
                                    UserRole != 'Admin')
                              "
                              class="mr-auto"
                              @click="moveToRestore(index, img)"
                            >
                              <u>restore</u>
                            </a>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="showCropperModal == true">
                  <image-cropper :allSelectedImages="allSelectedImages" />
                </div>

                <div class="vx-row">
                  <div class="vx-col mx-auto">
                    <vs-button
                      v-if="
                        file_upload &&
                          can_appr_upload &&
                          purchase_status == 1 &&
                          purchaseData.is_var != '3'
                      "
                      :disabled="
                        uploadedDocumentArray.length <= 0 &&
                        (purchaseData.is_var == '0' ||
                          purchaseData.is_var == '2')
                          ? true
                          : false
                      "
                      type="filled"
                      @click.prevent.native="resubmitconformation1()"
                      class="mr-3"
                    >
                      Update Documents
                    </vs-button>

                    <vs-button
                      v-else-if="
                        showresubmit &&
                          String(params.data.approver_id) == '0' &&
                          UserRole != 'Admin'
                      "
                      :disabled="
                        uploadedDocumentArray.length <= 0 &&
                        (purchaseData.is_var == '0' ||
                          purchaseData.is_var == '2')
                          ? true
                          : false
                      "
                      type="filled"
                      @click.prevent.native="resubmitconformation()"
                      class="mr-3"
                    >
                      Update and Resubmit for Approval
                    </vs-button>

                    <vs-button
                      v-else
                      :disabled="
                        purchase_status != 0 ||
                          !canEdit ||
                          disableUserLevelEdit == true ||
                          (uploadedDocumentArray.length <= 0 &&
                            (purchaseData.is_var == '0' ||
                              purchaseData.is_var == '2'))
                      "
                      type="filled"
                      @click.prevent.native="submitForm('resubmit=0')"
                      class="mr-3"
                    >
                      Update
                    </vs-button>

                    <vs-button
                      v-if="
                        user_is_verifier == true &&
                          purchaseData.is_verified == 0
                      "
                      type="filled"
                      @click.prevent.native="onClickVerifyExp"
                      class="mr-3"
                    >
                      Verify Expense
                    </vs-button>
                  </div>
                </div>
              </FocusLock>
            </form>
          </div>
        </div>
      </vs-popup>
    </div>

    <vs-popup
      style="z-index: 999999 !important"
      :id="'popmodalVerify'"
      title="Verify Expense "
      :active.sync="popupVerifyExp"
    >
      <div class="text-center">
        <h5>Description</h5>
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-textarea v-model="verifyComment" class="mb-4" rows="4" />
            <vs-button
              color="success"
              type="filled"
              @click="onVerifyExpense()"
              class="mr-3"
            >
              Submit
            </vs-button>

            <vs-button color="danger" type="filled" @click="closeVerify()">
              Cancel
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import MethodService from "@/services/methodService.js";
import projectService from "@/services/projectService.js";
import CountryService from "@/services/countryService.js";
import CategoryService from "@/services/categoryService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import VendorAdvanceRequestService from "@/services/VendorAdvanceRequestService.js";

import Datepicker from "vuejs-datepicker";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import moment from "moment";
import vue2Dropzone from "vue2-dropzone";
import constant from "@/helper/constant.js";
import { BASEURL } from "@/config/index";
import ImageCropper from "../projects/imageCropper.vue";
import _ from "lodash";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "swiper/dist/css/swiper.min.css";

export default Vue.extend({
  props: {
    editPoParamsData: Object,
    modalCurrentPage: Number,
  },
  data() {
    return {
      cityDisabled: true,
      showCropperModal: false,
      allSelectedImages: [],
      verifyComment: "",
      uploadedImage: "",
      file_upload: true,
      can_appr_upload: false,
      popupVerifyExp: false,
      uploadedDocument: "",
      uploadedDocumentArray: [],

      currentUploadededDoc: [],
      ignoredDocArray: [],
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      tempToken: window.localStorage.getItem("TempToken"),
      extensions: ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "webp", "WEBP"],
      swiperOption: {
        slidesPerView: 1,
        autoHeight: true,
        spaceBetween: 30,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      images: [],
      popupActiveImages: false,
      expenses_datas: [],
      expenses_create_by: "",
      showlog: false,
      user_is_verifier: false,
      user_pcr_balance: 0,
      user_verifier_name: null,
      user_verifier_reason: null,
      purchase_verified_date: null,
      showresubmit: false,
      gstList: [],
      tdsList: [],
      mod_user: [],
      LoginUserId: "",
      userLevelLogin: "",
      admin_user: [],
      purchaseData: {},
      rejectionComment: "",
      acceptComment: "",
      acceptCommentUpload: "",
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      popupActive: false,
      price: null,
      is_paid: null,
      tableRefresData: {},
      popupActive0: false,
      popupActive1: false,
      popupActive2: false,
      popupActive3: false,
      popupActive4: false,
      popupActive44: false,
      submitStatus: false,
      params: null,
      value: null,
      count: 0,
      user_id: "",
      purchase_status: "",
      map_id: "",
      pmap_id: "",
      project_id: "",
      purchase_id: "",
      form: {
        parent_id: "",
        category_id: "",
      },
      isPopup: null,
      POuser: "",
      acceptReason: "",
      levels: [],
      countries: [],
      cities: [],
      country_value: [],
      city_value: [],
      project_value: [],
      allProjects: [],
      alternateOrg: [],
      categories: [],
      showCat: false,
      showAltOrg: false,
      showSubCat: false,
      showCont: false,
      subcategories: [],
      categoryValue: {},
      subcategoryValue: {},
      adminManagerArray: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles:
          ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp,.doc,.docx",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      allowEdit: true,
      editableProjects: null,
      canEdit: false,
      disableUserLevelEdit: false,
      hideIcon: false,
      shootLocationList: [],
      locationValue: [],
      default_alt_org_id: "",
    };
  },
  components: {
    Datepicker,
    swiper,
    swiperSlide,
    vueDropzone: vue2Dropzone,
    ImageCropper,
  },
  watch: {
    rejectionComment: function(val) {
      this.rejectionComment = this.rejectionComment.replace("\n", "");
      this.rejectionComment = this.rejectionComment.slice(0, 240);
    },
    acceptComment: function(val) {
      this.acceptComment = this.acceptComment.replace("\n", "");
      this.acceptComment = this.acceptComment.slice(0, 240);
    },
    acceptCommentUpload: function(val) {
      this.acceptCommentUpload = this.acceptCommentUpload.replace("\n", "");
      this.acceptCommentUpload = this.acceptCommentUpload.slice(0, 240);
    },

    popupActive: function(val) {
      if (this.popupActive == false) {
        setTimeout(() => {
          eventBus.$emit(
            "refreshPOList",
            this.tableRefresData,
            this.modalCurrentPage
          );
        }, 0.5);
      }
    },
  },
  beforeMount() {
    if (this.editPoParamsData) {
      this.params = this.editPoParamsData;
    }
    this.purchase_status = this.params.data.purchase_status;
    this.form = this.params.data;
    this.proj_id = this.params.data.project_id;
    this.isPopup = this.params.data.isPopup;
    this.is_paid = this.params.data.is_already_paid;
    if (this.editPoParamsData) {
      this.hideIcon = true;
      this.showModal();
    }
    this.getShootLocationList();
    this.$vs.loading();
    setTimeout(() => {
      this.purchaseData.total =
        this.editPoParamsData.data.total || this.editPoParamsData.data.price;
      this.calcTotalAmount();

      this.subcategoryValue = {
        category_id: this.params.data.category_id,
        category_name: this.params.data.category_name,
      };

      this.$vs.loading.close();
    }, 2000);
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.purchase_id ||
        "popmodalr" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  computed: {
    UserRole() {
      return localStorage.getItem("UserRole") || null;
    },
  },
  methods: {
    openCropper() {
      this.showCropperModal = true;
    },
    onVerifyExpense() {
      let payload = {
        comment: this.verifyComment,
        user_id: this.LoginUserId,
        project_id: this.form.project_id,
        purchase_id: this.purchase_id,
      };
      VendorAdvanceRequestService.verifyExpense(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupVerifyExp = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    closeVerify() {
      this.popupVerifyExp = false;
    },
    onClickVerifyExp() {
      this.popupVerifyExp = true;
    },
    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == true) {
            this.shootLocationList = data.data;
            this.shootLocationList.map((x) => {
              if (this.params.data.location_id == x.location_id) {
                this.locationValue = x;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calcTotalAmount() {
      this.purchaseData.total_amount = (
        parseFloat(this.purchaseData.total || 0) +
        parseFloat(this.purchaseData.gst_amount || 0) -
        parseFloat(this.purchaseData.tds_amount || 0)
      ).toFixed(2);
    },
    clearFile: function() {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.uploadedDocumentArray = [];
      this.uploadedDocument = "";
      if (this.params.data.purchase_image) {
        this.uploadedDocumentArray = this.params.data.purchase_image.split(",");
        this.uploadedDocument = this.params.data.purchase_image;
      }
    },
    vdropzoneFilesAdded: function(file) {
      console.log("file 1 :", file);
    },
    vdropzoneSuccessFile: function(file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
    },

    vdropzoneSuccess: function(file, response) {
      this.user_pic = response.data.image_path;
      this.uploadedImage = response.data.image_path;

      let obj = {
        fileUiid: file[0].upload.uuid,
        filepath: response.data.image_path,
      };
      this.currentUploadededDoc.push(obj);
      this.uploadedDocumentArray.push(response.data.image_path);
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      this.imageUpload.deleteButtonStatus = true;
      this.fileUpload.deleteButtonStatus = true;
    },
    vdropzoneComplete: function(response) {
      for (let i = 0; i < response.length; i++) {
        if (!response[i].cropped) {
          this.allSelectedImages.push(response[i]);
        }
        this.showCropperModal = false;
      }
    },
    vdropzoneError: function(file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    moveToIgnore(index, img) {
      this.ignoredDocArray.push(img);
      this.uploadedDocumentArray.splice(index, 1);
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },
    moveToRestore(index, img) {
      this.uploadedDocumentArray.push(img);
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      this.ignoredDocArray.splice(index, 1);
    },

    downloadPdf(img, tempToken) {
      window.open(img + "?token=" + window.localStorage.getItem("TempToken"));
    },
    vremoved: function(file) {
      let rmv = this.currentUploadededDoc.filter((x) => {
        return x.fileUiid == file.upload.uuid;
      });
      let a = 0;
      if (rmv.length > 0) {
        a = this.uploadedDocumentArray.indexOf(rmv[0].filepath);
      }
      if (a > -1) {
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      if (this.uploadedDocumentArray.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
      }
      this.file_upload = true;
    },
    onClickCloseButton() {
      let obj = {
        budget_id: this.params.data.budget_id,
        show: true,
        budget_code: this.params.data.budget_code,
      };
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
    },
    showImages: function(images) {
      this.images = images.split(",");
      console.log(this.images, "this.images");
      this.popupActiveImages = true;
    },
    showLogs: function() {
      this.$vs.loading();
      purchaseOrderService
        .showLogs(this.params.data.purchase_id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          this.expenses_create_by = data.purchase_created_by;
          this.expenses_datas = data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
      this.popupActive0 = true;
    },
    getMasterList: function() {
      this.$vs.loading();
      MethodService.getAllMaster()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true) {
            this.gstList = data.data.gst;
            this.tdsList = data.data.tds;
            let Arry = this.gstList.filter((x) => {
              return x.gst_id == this.purchaseData.gst_id;
            });
            if (Arry.length > 0) {
              let obj = Arry[0];
              this.purchaseData.gst_id = obj;
            }

            let Arry2 = this.tdsList.filter((x) => {
              return x.tds_id == this.purchaseData.tds_id;
            });
            if (Arry2.length > 0) {
              let obj = Arry2[0];
              this.purchaseData.tds_id = obj;
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    gstCalculate(selected) {
      let amount = this.purchaseData.total
        ? parseFloat(this.purchaseData.total).toFixed(2)
        : 0;

      let gstAmount = ((selected ? selected.gst_percentage : 0) * amount) / 100;
      if (parseFloat(gstAmount) > 0) {
        this.purchaseData.gst_amount = parseFloat(gstAmount).toFixed(2);
      }

      this.calcTotalAmount();
    },

    tdsCalculate(selected) {
      let amount = this.purchaseData.total
        ? parseFloat(this.purchaseData.total).toFixed(2)
        : 0;

      let tdsAmount = ((selected ? selected.tds_percentage : 0) * amount) / 100;
      if (parseFloat(tdsAmount) > 0) {
        this.purchaseData.tds_amount = parseFloat(tdsAmount).toFixed(2);
      }

      this.calcTotalAmount();
    },

    showModal() {
      this.$vs.loading();
      this.popupActive = true;
      this.getMasterList();
      this.getProjectList();
      this.getCategoryList();
      this.getCountryList();
      this.getCityList();
      this.getPurchaseMapDetails();
    },
    getPurchaseMapDetails() {
      this.$vs.loading();
      let payload = {
        is_var: this.params.data.is_var,
      };

      purchaseOrderService
        .getPurchaseOrderMaping(this.params.data.purchase_id, payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.levels = [];
            this.purchaseMapDetails = data.data;
            this.adminManagerArray = data.adminManagerArray;
            this.showresubmit = data.showresubmit;
            this.showlog = data.showlog;
            this.editableProjects = data.editableProjects;
            this.user_is_verifier = data.user_is_verifier;
            this.user_pcr_balance = data.user_pcr_balance;
            this.user_verifier_name = data.user_verifier_name;
            this.user_verifier_reason = data.user_verifier_reason;
            this.purchase_verified_date = data.purchase_verified_date;

            if (this.editableProjects.length > 0) {
              if (
                this.editableProjects.filter((u) => u == this.proj_id).length >
                0
              ) {
                this.canEdit = true;
              } else {
                this.canEdit = false;
              }
            } else {
              this.canEdit = false;
            }

            this.can_appr_upload = data.can_appr_upload;

            let ModArray = data.data.filter(({ mod }) => String(mod) === "1");

            let otherArray = data.data
              .filter(({ mod, user_level }) => {
                return (
                  String(mod) !== "1" &&
                  (String(mod) !== "0" || String(user_level) !== "0")
                );
              })
              .reverse();

            this.mod_user = ModArray.length > 0 ? ModArray : this.mod_user;

            let AdminArray = data.data.filter(
              ({ mod, user_level }) => String(mod) === "2"
            );

            this.admin_user =
              AdminArray.length > 0 ? AdminArray : this.admin_user;
            this.LoginUserId = data.loggedInUserId;
            this.userLevelLogin = data.user_level;
            let levelGroup = _.groupBy(otherArray, "user_level");
            let reverse = Object.keys(levelGroup);
            this.levels = [];
            reverse.map((x) => {
              this.levels.push({
                levelName: x,
                level: levelGroup[parseInt(x)],
              });
            });
            for (let i = 0; i < this.levels.length; i++) {
              for (let j = 0; j < this.levels[i].level.length; j++) {
                if (
                  window.localStorage.getItem("user_id") ==
                    this.levels[i].level[j].user_id &&
                  (this.levels[i].level[j].purchase_map_status == 1 ||
                    this.levels[i].level[j].purchase_map_status == 2) &&
                  this.levels[i].level[j].is_admin_manager_user == 0
                ) {
                  this.disableUserLevelEdit = true;
                  break;
                }
              }
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    getProjectList: function() {
      this.$vs.loading();
      this.default_alt_org_id = "";
      projectService
        .getAllProjects()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.allProjects = data.data;
            this.allProjects.map((x) => {
              if (this.params.data.project_id == x.project_id) {
                this.project_value = x;
              }
            });

            if (data.alternateOrgNew.length > 0) {
              this.alternateOrg = data.alternateOrgNew;
              let selectedOrg = this.alternateOrg.filter((y) => {
                return (
                  y.default_alt_org_id == this.purchaseData.amount_paid_from
                );
              });

              if (selectedOrg.length > 0) {
                this.default_alt_org_id = selectedOrg[0];
              } else {
                this.default_alt_org_id = "";
              }
            } else {
              this.alternateOrg = [];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCategoryList: function() {
      this.$vs.loading();
      let ID = this.proj_id;
      CategoryService.getProjectMainCategories(ID)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
            this.categories.map((x) => {
              if (this.params.data.parent_id === x.category_id) {
                this.categoryValue = x;
                this.form.parent_id = x.category_id;
                this.getSubcategoryList(x.category_id);
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    onSuggestSelect: function(selected) {
      this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function(ID) {
      this.$vs.loading();
      let PId = this.proj_id;
      CategoryService.getSubCategoriesByParentCategory(ID, PId)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            this.subcategories.map((x) => {
              if (this.params.data.category_id === x.category_id) {
                this.subcategoryValue = x;
                this.form.category_id = x.category_id;
              }
            });
            if (data.data.length == 1) {
              this.subcategoryValue = this.subcategories[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message || "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    onSelectCountry({ country_id }) {
      this.getCityList(country_id);
    },
    getCountryList: function() {
      this.$vs.loading();
      CountryService.getAllCountry()
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.countries = data.data;
            this.countries.map((x) => {
              if (this.params.data.country_id == x.country_id) {
                this.country_value = x;
              }
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    getCityList: function(id) {
      this.$vs.loading();
      this.cities = [];
      this.city_value = null;
      this.city_id = null;
      CountryService.getCityByCountry(id ? id : this.params.data.country_id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.cities = data.data;
            this.cities.map((x) => {
              if (this.params.data.city_id == x.city_id) {
                this.city_value = x;
              }
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    submitForm: function(resubmit = "resubmit=0") {
      let bol = true;
      if (resubmit == "resubmit=1") {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
        } else {
          bol = false;
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      }

      if (resubmit == "resubmit=2") {
        resubmit = "resubmit=1";
        if (this.acceptCommentUpload.length > 0) {
          // this.acceptCommentUpload = this.acceptCommentUpload;
        } else {
          bol = false;
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      }
      if (
        this.uploadedDocumentArray.length <= 0 &&
        (this.purchaseData.is_var == "0" || this.purchaseData.is_var == "2")
      ) {
        bol = false;
        this.$vs.notify({
          title: "Please add atleast one document!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }

      if (bol) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.popupActive4 = false;
            this.popupActive44 = false;
            let payload = {
              uploadedImage: this.uploadedImage,
              reasonUpload: this.acceptCommentUpload,
              reason: this.acceptReason,
              project_id: this.purchaseData.project_id,
              user_id: this.purchaseData.user_id,
              po_name: this.purchaseData.po_name,
              item_name: this.purchaseData.item_name,
              company_name: this.purchaseData.company_name,
              company_address: this.purchaseData.company_address,
              price: this.purchaseData.total,
              currency: this.purchaseData.currency,
              VHC_code: this.purchaseData.VHC_code,
              sgst: "1",
              cgst: "1",
              total: this.purchaseData.total,
              purchase_image: this.uploadedDocument
                ? this.uploadedDocument
                : "-",
              ignored_image:
                this.ignoredDocArray.length > 0
                  ? this.ignoredDocArray.toString()
                  : "-",
              parent_id: this.form.parent_id,
              category_id: this.subcategoryValue.category_id,
              city_id:
                this.city_value && this.city_value.city_id
                  ? this.city_value.city_id
                  : 1,
              country_id: this.country_value.country_id,
              location_id: this.locationValue.location_id,
              purchase_description: this.purchaseData.purchase_description,
              purchase_status: this.purchaseData.purchase_status,
              approved_percentage: this.purchaseData.approved_percentage,
              purchase_active: this.purchaseData.purchase_active,
              approver_id: this.purchaseData.approver_id,
              invoice_number: this.purchaseData.invoice_number,
              default_alt_org_id: this.default_alt_org_id.default_alt_org_id,
              priority: this.purchaseData.priority,
              invoice_date: this.purchaseData.invoice_date
                ? moment(this.purchaseData.invoice_date).format("DD-MM-YYYY")
                : "",

              payment_due_date: this.purchaseData.payment_due_date
                ? moment(this.purchaseData.payment_due_date).format(
                    "DD-MM-YYYY"
                  )
                : "",
              gst_amount: this.purchaseData.gst_amount || 0,
              gst_id: this.purchaseData.gst_id
                ? this.purchaseData.gst_id.gst_id
                : 0,
              tds_amount: this.purchaseData.tds_amount || 0,
              tds_id: this.purchaseData.tds_id
                ? this.purchaseData.tds_id.tds_id
                : 0,
              total_amount: this.purchaseData.total_amount,
              vendor_id: this.purchaseData.vendor_id,
              is_var: this.params.data.is_var,
            };

            this.$vs.loading();
            purchaseOrderService
              .editPurchaseOrder(
                payload,
                this.params.data.purchase_id,
                resubmit
              )
              .then((response) => {
                this.$vs.loading.close();
                const { data } = response;
                if (data.Status == true) {
                  this.$vs.notify({
                    title: "Updated!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  eventBus.$emit("refreshPOList", data);
                } else {
                  this.$vs.notify({
                    title: "Error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                }
              })
              .catch((err) => {
                this.$vs.loading.close();
              });
          }
        });
      }
    },
    approvePO: function(user, type) {
      if (
        this.uploadedDocumentArray.length <= 0 &&
        (this.purchaseData.is_var == "0" || this.purchaseData.is_var == "2")
      ) {
        this.$vs.notify({
          title: "Please add atleast one document!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }

      if (type == ("user", "admin") && this.isPopup == 1) {
        this.POuser = user;
        this.popupActive2 = true;
      } else {
        this.POuser = user;
        this.popupActive3 = true;
      }
    },

    onSubmitPO: function() {
      if (this.isPopup == 1) {
        if (this.acceptComment.length > 0) {
          this.acceptReason = this.acceptComment;
          this.approvePurchaseOrder(this.POuser, this.acceptReason);
        } else {
          this.$vs.notify({
            title: "Please provide a reason!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      } else {
        this.acceptReason = this.acceptComment;
        this.approvePurchaseOrder(this.POuser, this.acceptReason);
      }
    },

    approvePurchaseOrder: function(user, reason) {
      this.$vs.loading();
      let obj = {
        project_id: user.project_id,
        purchase_id: this.params.data.purchase_id,
        user_id: user.user_id,
        reason: reason,
      };
      purchaseOrderService
        .approvePurchaseOrderDashboard(obj)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.getPurchaseMapDetails();
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.popupActive2 = false;
            this.popupActive3 = false;
            this.acceptComment = "";
            this.acceptReason = "";
            this.tableRefresData = data;
          } else {
            this.$vs.notify({
              title: "OOPS!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => this.$vs.loading.close());
    },
    resubmitconformation() {
      this.popupActive4 = true;
    },
    resubmitconformation1() {
      if (
        this.file_upload &&
        this.can_appr_upload &&
        this.purchase_status == 1
      ) {
        this.popupActive44 = true;
      } else {
        this.submitForm("resubmit=0");
      }
    },
    privStatus: function() {
      this.popupActive1 = false;
      this.popupActive2 = false;
      this.popupActive3 = false;
      this.popupActive4 = false;
      this.popupActive44 = false;
      this.popupActiveImages = false;
      this.popupActive0 = false;
    },
    onSubmit: function() {
      this.$vs.loading();
      if (this.rejectionComment.length > 0) {
        let obj = {
          project_id: this.project_id,
          purchase_id: this.params.data.purchase_id,
          user_id: this.user_id,
        };
        purchaseOrderService
          .rejectPurchaseOrderDashboard(obj, {
            reason: this.rejectionComment,
          })
          .then((response) => {
            this.$vs.loading.close();
            const { data } = response;
            if (data.Status == true) {
              this.popupActive1 = false;
              this.rejectionComment = "";
              this.user_id = "";
              this.map_id = "";
              this.getPurchaseMapDetails();
              this.$vs.notify({
                title: "Rejected!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.tableRefresData = data;
            } else {
              this.$vs.notify({
                title: "OOPS!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => this.$vs.loading.close());
      } else {
        this.$vs.notify({
          title: "Please provide a reason!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
      this.$vs.loading();
    },
    rejectPO: function(user) {
      if (
        this.uploadedDocumentArray.length <= 0 &&
        (this.purchaseData.is_var == "0" || this.purchaseData.is_var == "2")
      ) {
        this.$vs.notify({
          title: "Please add atleast one document!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }
      this.user_id = user.user_id;
      this.purchase_id = user.purchase_id;
      this.project_id = user.project_id;
      this.popupActive1 = true;
    },
  },
  mounted() {
    const next = this.$children[0].$refs.btnclose;
    next.$el.addEventListener("click", this.onClickCloseButton, false);

    if (this.params.data.purchase_image) {
      this.uploadedDocumentArray = this.params.data.purchase_image
        .split(",")
        .filter((x) => {
          return x != "-";
        });
    }
    if (
      this.params.data.ignored_image &&
      this.params.data.ignored_image != "-"
    ) {
      this.ignoredDocArray = this.params.data.ignored_image
        .split(",")
        .filter((x) => {
          return x != "-";
        });
    }

    this.purchase_id = this.params.data.purchase_id;
    this.purchaseData = this.params.data;
    if (!this.purchaseData.gst_id) {
      this.purchaseData.gst_amount = null;
    }

    if (!this.purchaseData.tds_id) {
      this.purchaseData.tds_amount = null;
    }

    if (this.purchaseData.ref_exp_inv_date) {
      this.endDisabledDates["to"] = new Date(this.form.ref_exp_inv_date);
      var expenseInvDate = new Date(this.form.ref_exp_inv_date);
      expenseInvDate.setDate(expenseInvDate.getDate() + 1);
      var sixMonthsFromExpDate = expenseInvDate.setMonth(
        expenseInvDate.getMonth() + 6
      );
      this.endDisabledDates["from"] = new Date(sixMonthsFromExpDate);
    }

    if (this.purchase_status == 1) {
      this.allowEdit = false;
    }
    eventBus.$on("UploadCropedImage", (payload) => {
      this.$refs.myVueDropzoneFile.removeFile(payload.fileToRemove);
      this.$refs.myVueDropzoneFile.addFile(payload.fileToAdd);
    });
    this.showCat = true;
    this.showAltOrg = true;
    this.showSubCat = true;
    this.showCont = true;
    setTimeout(() => {
      this.cityDisabled = false;
    }, 3000);
  },
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}

.popmodalViewR .vs-popup {
  width: 70% !important;
}
.outlevel {
  margin-right: 10px;
}
.inlevel {
  font-weight: bold;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}
</style>
