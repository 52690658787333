import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllTempInventory(filters, id) {
    // return Api().get(`/v2/inventory/project/${id}?archive=4`);
    if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/inventory/project/${id}/page/${filters.page}?archive=4&organization_id=${organization_id()}`);
    } else {
      return Api().get(`/v2/inventory/project/${id}?archive=4&organization_id=${organization_id()}`);
    }
  },
  getAllInventory(filters, id) {
    // return Api().get(`/v2/inventory/project/${id}`);
    if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/inventory/project/${id}/page/${filters.page}?archive=1&organization_id=${organization_id()}`);
    } else {
      return Api().get(`/v2/inventory/project/${id}?archive=1&organization_id=${organization_id()}`);
    }

  },

  editInventory(payload, id) {
    return Api().put(`/v2/inventory/${id}?organization_id=${organization_id()}`, payload);
  },

};
