<template>
  <div>
    <span
      :class="params.data.purchase_status != 1 ? 'view-icon-disabled' : ''"
      class="text-center table-edit-icon"
      @click="
        params.data.purchase_status != 1
          ? (this.popupActive = false)
          : showModal()
      "
    >
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <!-- POPUP -->
    <div class>
      <vs-popup
        :id="'popmodal' + params.data.purchase_id"
        title="Challan Detail"
        :active.sync="popupActive"
      >
        <!-- <div :key="imageKey" v-for="(image,imageKey) in images">
          <img :src="image+'?token='+tempToken" alt />
        </div>-->

        <swiper
          v-if="challanDetails.length != 0"
          :options="swiperOption"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          :key="$vs.rtl"
        >
          <swiper-slide
            :key="challanKey"
            v-for="(challan, challanKey) in challanDetails"
          >
            <div class="col-12">
              <div class="row">
                <swiper
                  :options="swiperOption1"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :key="$vs.rtl"
                >
                  <swiper-slide
                    v-for="(image, imageKey) in challan.images"
                    :key="imageKey"
                  >
                    <div
                      class="
                        vx-col
                        w-full
                        align-self-center
                        text-center
                        image-box
                        rounded
                      "
                    >
                      <!-- <a :href="image" download> -->
                      <img
                        class="responsive text-center"
                        :src="image + '?token=' + tempToken"
                        alt="banner"
                      />
                      <!-- @click.prevent.stop="downloadWithAxios(image)" -->
                      <!-- <vs-button class="dwn-btn" @click.prevent="downloadItem(image,challan)">
                        Download
                        <i class="text-center material-icons align-bottom">get_app</i>
                      </vs-button>-->

                      <!-- </a> -->
                    </div>
                  </swiper-slide>
                </swiper>
                <a
                  :href="
                    excelurl +
                    '/v2/challan/download/' +
                    challan_no +
                    '?organization_id=' +
                    organization_id +
                    '&token=' +
                    tempToken
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Download</span>
                  </vs-button>
                </a>
                <div class="vx-col w-full py-5">
                  <div class="mx-0 row mb-6">
                    <div class="col-6">
                      <div>
                        <p class="text-muted small mb-1">Challan Name:</p>
                        <div class>
                          <h5>{{ challan.challan_no }}</h5>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div>
                        <p class="text-muted small mb-1">
                          Challan Description:
                        </p>
                        <div class>
                          <h5>{{ challan.challan_description }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mx-0 row mb-6">
                    <div class="col-6">
                      <div>
                        <p class="text-muted small mb-1">Challan Amount</p>
                        <div class>
                          <h5>
                            {{ challan.currency }} {{ challan.challan_amount }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div>
                        <p class="text-muted small mb-1">Created By:</p>
                        <div class>
                          <h5>{{ challan.user_name }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mx-0 row mb-6">
                    <div class="col-6">
                      <div>
                        <p class="text-muted small mb-1">PO Name:</p>
                        <div class>
                          <h5>{{ challan.po_name }}</h5>
                        </div>
                      </div>
                    </div>
                    <!-- 
                    <div v-if="projectDetail.payment_mode != 0" class="col-6">
                      <div>
                        <p class="text-muted small mb-1">Mode of Payment</p>
                        <div class>
                          <h5></h5>
                        </div>
                      </div>
                    </div>-->
                  </div>

                  <!-- <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full text-right align-self-center">
              <h5 class="mb-0">Mode of Payment</h5>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <div class>
                <span>card</span>
              </div>
            </div>
                  </div>-->
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>

        <div v-else>
          <h3>NO DATA FOUND</h3>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import companyService from "@/services/companyService.js";
import purchaseOrderService from "@/services/purchaseOrderService";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";
// import imageDataURI from "image-data-uri";
import { BASEURL } from "@/config/index.js";

export default Vue.extend({
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      tempToken: window.localStorage.getItem("TempToken"),
      excelurl: BASEURL,
      images: [],
      challanDetails: {},
      popupActive: false,
      params: null,
      challanDe: [],
      value: null,
      count: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption1: {
        slidesPerView: 2,
        autoHeight: true,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      organization_id: window.localStorage.getItem("OrganizationID"),
    };
  },
  watch: {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  beforeMount() {
    this.challan_no = this.params.data.challan_no;
  },
  methods: {
    downloadWithAxios(image) {
      axios({
        method: "get",
        url: image,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    downloadItem(url, challan) {
      // axios
      //   .get(url, { responseType: "blob" })
      //   .then(({ data }) => {
      //     console.log("data :", data);
      //     const blob = new Blob([data], { type: "image/jpeg" });
      //     const link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.download = label;
      //     link.click();
      //     URL.revokeObjectURL(link.href);
      //   })
      //   .catch(error => console.error(error));
      let ext = url.split(".");
      // imageDataURI
      //   .encodeFromURL(url)
      //   .then((uri) => {
      //     console.log("uri :", uri);
      //     // setCreatingURI(false);

      //     const link = document.createElement("a");
      //     link.href = uri;
      //     link.download = challan.po_name + ext[1];
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch((err) => {
      //     console.log("object", err);
      //     // setCreatingURI(false);
      //   });
    },
    paymentMode: function (val) {
      console.log("val :", val);
      if (val == 1) {
        return "Cash";
      } else if (val == 3) {
        return "Debit";
      } else if (val == 2) {
        return "Credit";
      }
    },
    showModal() {
      this.popupActive = true;
      console.log("this.params ::::", this.params);
      let payload = {};
      let id = this.params.value;
      purchaseOrderService
        .getchallanDetails(payload, id)
        .then((response) => {
          const { data } = response;
          console.log("data :", data);
          if (data && data.Status == true) {
            this.challanDetails = data.data;
            this.challanDetails = this.challanDetails.map((x) => {
              x["images"] = x.challan_image.split(",");
              return x;
            });
            console.log("this.challanDetails :", this.challanDetails);
          } else {
          }
        })
        .catch((err) => {});
    },
    submitForm() {},
  },

  mounted() {
    // this.images.push(this.params.value);
  },
});
</script>
<style>
</style>