import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addPurchaseOrder(payload) {
    return Api().post(
      `/v2/purchase?organization_id=${organization_id()}`,
      payload
    );
  },
  showLogs(purchase_id) {
    return Api().get(
      `/v2/purchase/logs/${purchase_id}?organization_id=${organization_id()}`
    );
  },
  editPurchaseOrder(payload, id, data) {
    return Api().put(
      `/v2/purchase/${id}?organization_id=${organization_id()}&${data}`,
      payload
    );
  },

  getAllPurchaseOrder(filters, sort) {
    return Api().get(`/v2/purchase?organization_id=${organization_id()}`);
  },
  getPurchaseOrder(payload, id) {
    return Api().get(
      `/v2/purchase/${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  getchallanDetails(payload, id) {
    return Api().get(
      `/v2/challan/purchase/${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  getChallan(id) {
    return Api().get(
      `/v2/challan/purchase/${purchase_id}?organization_id=${organization_id()}`
    );
  },
  getPurchaseOrderMaping(id, payload) {
    console.log(payload, "payload");
    let isVar = 0;
    if (payload.is_var) {
      isVar = 1;
    } else {
      isVar = 0;
    }
    return Api().get(
      `/v2/purchase/purchaseMapDetails/${id}?organization_id=${organization_id()}&isDashboard=1&isVar=${isVar}`
    );
  },
  getAllPurchaseOrderByProject(id, payload) {
    let url = `/v2/purchase/project/${id}?isDashboard=1&organization_id=${organization_id()}&type=${payload.type}`;
    if (payload.hasOwnProperty("page_no")) {
      url += `&page_no=${payload.page_no}`;
    }

    if (payload.hasOwnProperty("purchaseStatus") && payload.purchaseStatus != null) {
      url += `&purchaseStatus=${payload.purchaseStatus}`;
    }

    if (payload.hasOwnProperty("vhc_code")) {
      if (payload.vhc_code && payload.vhc_code != "") {
        url += `&vhc_code=${payload.vhc_code}`;
      }
    }
    if (payload.hasOwnProperty("created_user_id")) {
      if (payload.created_user_id && payload.created_user_id != "") {
        url += `&created_user_id=${payload.created_user_id}`;
      }
    }
    if (payload.hasOwnProperty("priority")) {
      if (payload.priority && payload.priority != "") {
        url += `&priority=${payload.priority}`;
      }
    }
    if (payload.hasOwnProperty("selectedVendor")) {
      if (payload.selectedVendor && payload.selectedVendor != "") {
        url += `&selectedVendor=${payload.selectedVendor}`;
      }
    }
    if (payload.hasOwnProperty("tallySync")) {
      if (payload.tallySync == "All") {
      } else if (payload.tallySync == "Approved") {
        url += `&purchaseStatus=1`;
      } else if (payload.tallySync == "Synced") {
        url += `&tallyType=0`;
      } else if (payload.tallySync == "Unsynced") {
        url += `&tallyType=1`;
      }
    }

    if (payload.hasOwnProperty("is_var")) {
      if (payload.is_var == "1") {
        url += `&is_var=1`;
      } else if (payload.is_var == "2") {
        url += `&is_var=2`;
      } else if (payload.is_var == "3") {
        url += `&is_var=3`;
      } else {
        url += `&is_var=0`;
      }
    } else {
      url += `&is_var=0`;
    }
    if (payload.hasOwnProperty("search")) {
      url += `&search=${payload.search}`;
    }
    if (payload.hasOwnProperty("limit")) {
      url += `&limit=${payload.limit}`;
    }
    if (payload.hasOwnProperty("sort_by")) {
      url += `&sort_by=${payload.sort_by}`;
    }

    return Api().get(url);
  },
  approvePurchaseOrder(id, payload) {
    return Api().post(
      `/v2/purchase/approve/${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  approvePurchaseOrderDashboard(obj, payload) {
    return Api().post(`/v2/purchase/modapprove/${obj.project_id}/${obj.purchase_id}/${obj.user_id}?organization_id=${organization_id()}`, obj);
  },
  rejectPurchaseOrder(id, payload) {
    return Api().post(
      `/v2/purchase/reject/${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  rejectPurchaseOrderDashboard(obj, payload) {
    return Api().post(
      `/v2/purchase/modreject/${obj.project_id}/${obj.purchase_id}/${obj.user_id}?organization_id=${organization_id()}`,
      payload
    );
  },
  getApprovedPurchaseOrder(id) {
    return Api().get(
      `/v2/purchase/challan/${id}?organization_id=${organization_id()}`
    );
  },
  getCreditNoteList() {
    return Api().get(`/v2/creditNote?organization_id=${organization_id()}`);
  },

  deletePurchase(id) {
    return Api().delete(`/v2/purchase/${id}?organization_id=${organization_id()}`);
  },

  getPurchase(payload) {
    return Api().post(`/v2/purchase/getPurchaseData?organization_id=${organization_id()}`, payload);
  },

};
