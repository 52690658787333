import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addChallan(payload) {
    return Api().post(`/v2/challan?organization_id=${organization_id()}`, payload);
  },
  getAllChallan(filters, sort) {
    return Api().get(`/v2/challan?organization_id=${organization_id()}`);
  },
};
