<template>
  <div>
    <!-- Approval popup -->
    <vs-popup title="Reason" :active.sync="reasonModal">
      <div class="text-center">
        <h5>Reason For approval</h5>
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-textarea v-model="reason" class="mb-4" rows="4" counter="240" />
            <vs-button
              color="success"
              type="filled"
              @click="onSubmit"
              class="mr-3"
            >
              Submit
            </vs-button>

            <vs-button color="danger" type="filled" @click="privStatus">
              Cancel
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="row align-items-center justify-between">
      <div class="col-sm-4">
        <vs-button
          v-if="selectedRows.length > 0"
          @click="
            () => {
              this.reasonModal = true;
            }
          "
        >
          Approve Selected
        </vs-button>
      </div>

      <div class="col-sm-8 row justify-end">
        <!-- <h6>Project</h6> -->
        <div class="">
          <vs-input
            v-model="search"
            placeholder="Search..."
            class="mb-2 sm:mr-2"
            icon-no-border
          />
        </div>
        <div class="col-6">
          <multiselect
            label="project_name"
            trackBy="project_id"
            class="w-full"
            v-model="project"
            :options="projectList"
            :searchable="true"
            open-direction="bottom"
            placeholder="Select Project"
            deselect-label="Remove"
            autocomplete="off"
          />
        </div>
      </div>
    </div>

    <AgGridVue
      :gridOptions="gridOptions"
      :getRowId="(params) => params.data.purchase_id"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="listData"
      :rowSelection="'multiple'"
      @selection-changed="onSelectionChanged"
      :paginationPageSize="paginationPageSize"
      suppressRowClickSelection
    />
    <div class="flex items-center justify-between">
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />

      <div class="mb-2">
        Show&nbsp;
        <multiselect
          :options="limits"
          v-model="limit"
          track-by="value"
          label="label"
          selected-label=""
          select-label=""
          deselect-label=""
          class="inline-block w-16"
          size="sm"
        />&nbsp;Entries Per Page
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import editPoOrder from "../projects/editPoOrder.vue";
import ExpenseService from "@/services/expenseService.js";
import ProjectService from "@/services/projectService.js";
export default {
  props: {
    expenseStatus: { type: Number, required: true },
    isMyPO: { type: Boolean, default: false },
  },
  components: { AgGridVue, editPoOrder },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getListdata(val);
          return val;
        } else return 1;
      },
    },
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
    project() {
      this.getListdata();
    },
    search(val) {
      if (val && val.length >= 2) {
        this.$nextTick(() => {
          this.getListdata();
        });
      } else if (val == "") {
        this.$nextTick(() => {
          this.getListdata();
        });
      }
    },
    limit() {
      this.$nextTick(() => {
        this.getListdata();
      });
    },
  },
  data() {
    return {
      gridOptions: {},
      maxPageNumbers: 4,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "  ",
          field: "name",
          width: 70,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Name",
          field: "po_name",
          minWidth: 200,
          filter: true,
        },
        {
          headerName: "User",
          field: "user_name",
          minWidth: 200,
          maxWidth: 300,
          filter: true,
        },
        {
          headerName: "VHC Code",
          field: "VHC_code",
          filter: true,
          width: 256,
        },
        {
          headerName: "Invoice Number",
          field: "invoice_number",
          filter: true,
          width: 256,
        },
        {
          headerName: "Total Amount",
          field: "formattedAmount",
          filter: true,
          width: 150,
        },
        {
          headerName: "Verified Status",
          field: "is_verified",
          filter: true,
          width: 275,
          cellRenderer: function(params) {
            return params.value == 1 ? `Verified` : "Not Verified";
          },
        },
      ],
      listData: [],
      selectedRows: [],
      projectList: [],
      project: null,
      reasonModal: false,
      reason: "",
      limits: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      limit: { value: 10, label: 10 },
      search: "",
      totalPages: 0,
    };
  },
  methods: {
    getListdata(page = 1) {
      this.$vs.loading();
      let filters = {
        purchaseStatus: this.expenseStatus || 0,
        page,
        limit: this.limit.value,
        search: this.search,
      };

      if (this.project) {
        filters.project_id = this.project.project_id;
      }

      ExpenseService[!this.isMyPO ? "getUserExpenses" : "getMyExpenses"](
        filters
      )
        .then((response) => {
          const { data } = response;
          if (data.Status && data.data.length > 0) {
            this.listData = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
            this.listData = [];
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("error :", error);
        });

      // if (!this.isMyPO) {
      //   ExpenseService.getUserExpenses(filters)
      //     .then((response) => {
      //       const { data } = response;
      //       if (data.Status && data.data.length > 0) {
      //         this.listData = data.data;
      //         this.totalPages = data.pagination.last_page;
      //       } else {
      //         this.listData = [];
      //       }
      //       this.$vs.loading.close();
      //     })
      //     .catch((error) => {
      //       this.$vs.loading.close();
      //       console.log("error :", error);
      //     });
      // } else {
      //   ExpenseService.getMyExpenses(filters)
      //     .then((response) => {
      //       const { data } = response;
      //       if (data.Status && data.data.length > 0) {
      //         this.listData = data.data;
      //         this.totalPages = data.pagination.last_page;
      //       } else {
      //         this.listData = [];
      //       }
      //       this.$vs.loading.close();
      //     })
      //     .catch((error) => {
      //       this.$vs.loading.close();
      //       console.log("error :", error);
      //     });
      // }
    },
    onCellClicked(a, b, c) {
      console.log("a, b, c => ", a, b, c);
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
    },

    getProjectsList() {
      ProjectService.getAllProjects({}, localStorage.getItem("user_id"))
        .then((response) => {
          if (response.data.Status) {
            this.projectList = response.data.data;
          } else {
            this.projectList = [];
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    approveSelected() {
      const selectedExpense = this.selectedRows.map((item) => item.purchase_id);
      if (!selectedExpense.length) {
        return this.$vs.notify({
          title: "Warning!",
          text: "Expense(s) not selected.",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }

      ExpenseService.approveMultipleExpenses({
        selectedExpense,
        reason: this.reason,
      })
        .then((response) => {
          const { data } = response;
          if (!data.Status) {
            return this.$vs.notify({
              title: "Warning!",
              text: data.Message || "Expense(s) not selected.",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          this.selectedRows = [];
          this.$vs.loading.close();
          return this.$vs.notify({
            title: "Success!",
            text: data.Message || "Expense(s) approved.",
            iconPack: "feather",
            icon: "check_box",
            color: "success",
            time: 6000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("error :", error);
        });
    },

    onSubmit() {
      if (!this.reason) {
        return this.$vs.notify({
          title: "Please provide a reason!",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
      this.approveSelected();
      this.reasonModal = false;
      this.reason = "";
      this.getListdata();
    },
    privStatus() {
      this.reasonPopup = false;
      this.reason = "";
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.getListdata();
    this.getProjectsList();
    if (this.expenseStatus !== 0 || this.isMyPO) {
      this.columnDefs.shift();
    }
  },
};
</script>
