<template>
  <div>
    <!-- :pagination="true"
          :paginationPageSize="paginationPageSize"
    :suppressPaginationPanel="true"-->
    <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-end items-center">
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <h5 class="mr-5 w-full sm:w-auto">
              Total Amount:
              <span class="text-primary font-bold">{{
                totalExpense.toLocaleString("en-IN")
              }}</span>
            </h5>
            <vs-input
              class="mb-4 mr-0 sm:mr-4 w-full sm:w-auto"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <!-- <vx-tooltip text="Export Excel"> -->
            <!-- <a
                :href="excelurl+`/v1/expenses/excel/download?project_id=${this.projectID}&approver_id=1search=${searchQuery}`"
                target="_blank"
              >

            </a>-->
            <!-- </vx-tooltip> -->

            <!-- <a
              :href="
                excelurl +
                `/v2/purchase/excel/download?organization_id=${organization_id}&token=${temp_token}&project_id=${this.projectID}&approver_id=0&search=${searchQuery}`
              "
              target="_blank"
            >
              <vs-button class="px-4">
                <span class="d-inline-flex pr-5 text-white">Export</span>
                <img
                  src="@/assets/images/icons/export-excel.svg"
                  class="d-inline-flex"
                  height="20"
                  width="20"
                />
              </vs-button>
            </a> -->

            <vs-button
              class="px-4 mb-4 w-full sm:w-auto"
              @click.native="downloadExcel"
            >
              <span class="d-inline-flex pr-5 text-white ">Export </span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="expenseList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        />
        <vs-pagination
          :total="totalPageCheck"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div v-if="showimageModal == true">
      <image-pop-up :imagePopParamsData="imagePopParamsData" />
    </div>
    <div v-if="showExpDetailsModal == true">
      <expense-details :expDetailsParamsData="expDetailsParamsData" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import flatPickr from "vue-flatpickr-component";
import * as XLSX from "xlsx";
import UserService from "@/services/UserService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import expenseService from "@/services/expenseService.js";
import moveToBudget from "./moveToBudget.vue";
import { BASEURL } from "@/config/index.js";
import expenseDetails from "../projects/expenseDetails.vue";
import imagePopUp from "../projects/imagePopUp.vue";

import "flatpickr/dist/flatpickr.css";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    flatPickr,
    imagePopUp,
    expenseDetails,
  },
  props: ["expenseList", "CurrentPage", "totalPageCheck", "tabIndex", "allExp"],

  data() {
    return {
      showPoOrderModal: false,
      showPoOrder1Modal: false,
      editPoParamsData: null,
      editPo1ParamsData: null,
      imagePopParamsData: null,
      expDetailsParamsData: null,
      showExpDetailsModal: false,
      showimageModal: false,
      isActiveFirst: true,
      configTodateTimePicker: {
        maxDate: new Date(),
        minDate: "",
      },
      configFromdateTimePicker: {
        maxDate: new Date(),
        minDate: "",
      },
      excelurl: BASEURL,
      // expense: this.expenseList,
      User: "Select User",
      users: [],
      users_option: [],
      projects: [],
      projects_option: [],
      categories: [],
      category_option: [],
      statuses_option: [],
      form: {
        project_id: "",
        user_id: "",
        category_id: "",
        status_id: "",
        dateFrom: "",
        dateTo: "",
      },
      statuses: [
        { status_name: "Approve", status_id: 1 },
        { status_name: "Rejected", status_id: 2 },
        { status_name: "Pending", status_id: 0 },
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
          width: 275,
          filter: true,
          // checkboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // headerCheckboxSelection: true,
        },
        {
          headerName: "User",
          field: "user_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "Expense Name",
          field: "po_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "VHC Code",
          field: "VHC_code",
          filter: true,
          width: 275,
        },

        {
          headerName: "Subcategory",
          field: "category_name",
          filter: true,
          width: 375,
        },
        {
          headerName: "Amount",
          field: "total_amount",
          filter: true,
          width: 200,
        },
        {
          headerName: "Payment Due Date",
          field: "payment_due_date",
          filter: true,
          width: 200,
        },
        {
          headerName: "Status",
          field: "purchase_status",
          filter: false,
          sortable: false,
          width: 150,
          cellRenderer: function(params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            }
          },
        },
        {
          headerName: "Details",
          field: "exp_det",
          filter: true,
          width: 150,
          cellRenderer: function(params) {
            return '<span class="text-center table-edit-icon""> <i class="text-center material-icons">remove_red_eye</i>  </span>';
          },
          // cellRendererFramework: expenseDetails,
        },
        {
          headerName: "Multiple Images",
          field: "purchase_image",
          filter: true,
          width: 200,
          sortable: false,
          cellRenderer: function(params) {
            return params.data.hasOwnProperty("purchase_image") &&
              params.data.purchase_image != "-"
              ? '<span class="text-center table-edit-icon"> <i class="text-center material-icons">info</i> </span>'
              : '    <span class="text-center">No Images</span>';
          },
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
  },

  computed: {
    totalExpense() {
      return this.expenseList.reduce(
        (acc, obj) => (acc += obj.total_amount ? obj.total_amount : obj.total),
        0
      );
    },
    expenseFilter() {
      return this.$store.getters.expenseFilter;
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (this.tabIndex == 0) {
          eventBus.$emit("getPageNumber", val);
        }
      },
    },
  },
  methods: {
    onCellClicked(params) {
      if (
        params.colDef.field == "purchase_image" &&
        params.data.purchase_image &&
        params.data.purchase_image != "-"
      ) {
        this.showimageModal = false;
        this.imagePopParamsData = params;
        setTimeout(() => {
          this.showimageModal = true;
        }, 300);
      }
      if (params.colDef.field == "exp_det") {
        this.showExpDetailsModal = false;
        this.expDetailsParamsData = params;
        setTimeout(() => {
          this.showExpDetailsModal = true;
        }, 300);
      }
    },
    downloadExcel: function() {
      let data = [];
      for (var i = 0; i < this.allExp.length; i++) {
        var odata = this.allExp[i];

        var obj = {};
        obj.ProjectName = odata.project_name;
        obj.UserName = odata.user_name;
        obj.ExpenseName = odata.po_name;
        obj.VHCCode = odata.VHC_code;
        obj.CategoryName = odata.parent_name;
        obj.SubCategory_Name = odata.category_name;
        obj.SetName = odata.location_name;
        obj.Amount = odata.total_amount;
        obj.PaymentDueDate = odata.payment_due_date;
        obj.Invoice_Number = odata.invoice_number;
        obj.Invoice_Date = odata.invoice_date;

        if (odata.purchase_status == 0) {
          obj.Status = "Pending";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = "NA";
        } else if (odata.purchase_status == 1) {
          obj.Status = "Approved";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = odata.approvedDate;
        } else {
          obj.Status = "Rejected";
          obj.CreatedDate = odata.created_date;
          obj.Approved_Reject_Date = odata.approvedDate;
        }

        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "Expenses");
      XLSX.writeFile(wb, `expenssList.xlsx`);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
