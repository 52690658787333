<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card class="form-title" title="Find Expense ">
          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Project</h6>
              <multiselect
                v-model="projects_option"
                track-by="project_id"
                label="project_name"
                @input="({ project_id }) => (this.form.project_id = project_id)"
                :options="projects"
                name="Project Name"
                @select="getCategory"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-6 col-sm-6  mb-3">
              <h6>User</h6>
              <multiselect
                v-model="users_option"
                track-by="user_id"
                label="user_name"
                @input="({ user_id }) => (this.form.user_id = user_id)"
                :options="users"
                name="User Name"
                placeholder="Select User"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('User Name')"
                  >{{ errors.first("User Name") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Category</h6>
              <multiselect
                v-model="categoryValue"
                track-by="category_id"
                label="category_name"
                @input="
                  ({ category_id }) => (this.form.category_id = category_id)
                "
                :options="categories"
                name="Category Name"
                placeholder="Select Category"
                @select="onSuggestSelect"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Category Name')"
                  >{{ errors.first("Category Name") }}</span
                >
              </p>
            </div>

            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Subcategory</h6>
              <multiselect
                v-model="subcategoryValue"
                track-by="category_id"
                @input="
                  ({ category_id }) => (this.form.category_id = category_id)
                "
                label="category_name"
                :options="subcategories"
                name="subcategory Name"
                placeholder="Select Category"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('subcategory Name')"
                  >{{ errors.first("subcategory Name") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-12 col-md-2 col-sm-4">
              <h6>Status</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <multiselect
                    selectLabel=""
                    v-model="statuses_option"
                    track-by="status_id"
                    label="status_name"
                    @input="
                      ({ status_id }) => (this.form.status_id = status_id)
                    "
                    :options="statuses"
                    name="Status Name"
                    placeholder="Select Status"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Status Name')"
                      >{{ errors.first("Status Name") }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2 col-sm-4">
              <h6>Verify Status</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <multiselect
                    selectLabel=""
                    v-model="verified_status"
                    track-by="verify_id"
                    label="status_name"
                    @input="
                      ({ verify_id }) => (this.form.verify_id = verify_id)
                    "
                    :options="VerifiedStatus"
                    name="Status Name"
                    placeholder="Select Status"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Status Name')"
                      >{{ errors.first("Status Name") }}</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-2 col-sm-4">
              <h6>Priority</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <multiselect
                    selectLabel=""
                    v-model="priority_option"
                    track-by="priority_id"
                    label="priority_name"
                    @input="
                      ({ priority_id }) => (this.form.priority_id = priority_id)
                    "
                    :options="priority"
                    name="Status Name"
                    placeholder="Select Priority"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Status Name')"
                      >{{ errors.first("Status Name") }}</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-sm-6">
              <h6>Date</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <datepicker
                    placeholder="From Date"
                    :disabledDates="startDisabledDates"
                    @selected="onSelectDate($event)"
                    format="dd-MM-yyyy"
                    v-model="form.dateFrom"
                  />
                </div>
                <div class="w-100 ml-2">
                  <datepicker
                    placeholder="To Date"
                    :disabledDates="endDisabledDates"
                    v-model="form.dateTo"
                    format="dd-MM-yyyy"
                    :disabled="toDateDisable"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Pan Card number</h6>
              <multiselect
                v-model="form.vendorPan"
                track-by="vendor_id"
                label="vendor_pan"
                :options="pancardList"
                name="Vendor Pan"
                placeholder="Select Vendor Pan"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Vendor Pan')"
                  >{{ errors.first("Vendor Pan") }}</span
                >
              </p>
            </div>

            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>VHC Code</h6>
              <multiselect
                v-model="form.vhcCode"
                track-by="VHC_code"
                label="VHC_code"
                :options="VHCList"
                name="VHC Code"
                placeholder="VHC Code"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('VHC Code')"
                  >{{ errors.first("VHC Code") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row" v-if="isTally == 1">
            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Vendor</h6>
              <multiselect
                v-model="vendor_options"
                track-by="vendor_id"
                label="vendor_name"
                @input="({ vendor_id }) => (this.form.vendor_id = vendor_id)"
                :options="vendors"
                name="Vendor Name"
                placeholder="Select Vendor"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Vendor Name')"
                  >{{ errors.first("Vendor Name") }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-6 col-sm-6 mb-2">
              <h6>Amount Paid From</h6>
              <multiselect
                label="alt_org_name"
                class="w-full"
                value="default_alt_org_id"
                v-model="form.default_alt_org_id"
                name="Name"
                :options="alternateOrg"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
                autocomplete="off"
              />
            </div>
            <div class="col-12 col-md-6 col-sm-6 mb-3">
              <h6>Invoice Number</h6>
              <vs-input
                type="text"
                name="Invoice Number"
                v-model="form.invoice_number"
                class="w-full"
                placeholder="Enter Invoice Number"
              />

              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Invoice Number')"
                  >{{ errors.first("Invoice Number") }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-6 col-sm-6 mb-2">
              <h6>Set Name</h6>
              <multiselect
                label="location_name"
                class="w-full"
                track-by="location_id"
                v-model="form.shootLocation"
                name="location_name"
                :options="shootLocationList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('location_name')"
                  >{{ errors.first("location_name") }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-3 col-sm-4 mb-2">
              <h6>Payment Due Date</h6>
              <datepicker
                placeholder="To Date"
                v-model="form.payment_due_date"
                format="dd-MM-yyyy"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Payment Due Date')"
                  >{{ errors.first("Payment Due Date") }}</span
                >
              </p>
            </div>
            <div class="col-12 col-md-3 col-sm-4 mb-2">
              <h6>Tally Expenses</h6>
              <multiselect
                label="syncType"
                class="w-full"
                track-by="syncId"
                v-model="form.tallyType"
                name="syncType"
                :options="tallyTypeList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              />

              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('syncType')"
                  >{{ errors.first("syncType") }}</span
                >
              </p>
            </div>

            <div
              class="col-12 col-md-3 col-sm-4 mb-2"
              v-if="organization_id == 5"
            >
              <h6>Fixed/Variable Budget</h6>
              <multiselect
                label="type"
                class="w-full"
                track-by="id"
                v-model="form.fixvar"
                name="type"
                :options="fixvarList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg text-sm">
                <span class="text-danger text-sm" v-show="errors.has('type')">{{
                  errors.first("type")
                }}</span>
              </p>
            </div>

            <div class="col-12 col-md-3 col-sm-4 mb-2">
              <h6>Budget Code</h6>
              <multiselect
                class="w-full"
                v-model="form.budget_code"
                name="Budget Code"
                :options="budgetCodes"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('budget_code')"
                  >{{ errors.first("budget_code") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 mx-auto text-center">
              <vs-button
                class="mr-3 mb-3 w-full sm:w-auto"
                :disabled="submitStatus"
                @click.prevent="submitForm()"
              >
                Submit
              </vs-button>
              <vs-button
                color="warning"
                type="border"
                class="mb-3 w-full sm:w-auto"
                @click.prevent="clearForm()"
              >
                Reset
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-col w-full">
      <vs-tabs v-model="tabIndexCheck">
        <vs-tab label="Expenses" icon-pack="feather" icon="icon-box">
          <div>
            <expense-list
              :expenseList.sync="expense"
              :totalPageCheck="totalPages"
              :tabIndex="tabIndexCheck"
              :allExp="allExp"
            />
          </div>
        </vs-tab>

        <vs-tab
          v-if="show_po == true"
          label="Purchase Orders"
          icon-pack="feather"
          icon="icon-box"
        >
          <div class="tab-text">
            <div>
              <po-list
                :expenseList.sync="po_list"
                :tabIndex="tabIndexCheck"
                :totalPageCheck="totalPages"
              />
            </div>
          </div>
        </vs-tab>

        <vs-tab label="Cash Request" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <div>
              <cr-list
                :expenseList.sync="cr_list"
                :tabIndex="tabIndexCheck"
                :totalPageCheck="totalPages"
                :allExp="allExp"
              />
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import UserService from "@/services/UserService.js";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import expenseService from "@/services/expenseService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import Datepicker from "vuejs-datepicker";
import { BASEURL } from "@/config/index.js";
import flatPickr from "vue-flatpickr-component";

import poList from "@/views/apps/expenses/poList.vue";
import crList from "@/views/apps/expenses/crList.vue";

import expenseList from "@/views/apps/expenses/expenseList.vue";
import moment from "moment";

import "flatpickr/dist/flatpickr.css";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import BudgetService from "@/services/budgetService.js";

export default {
  components: {
    AgGridVue,
    flatPickr,
    VxTour,
    poList,
    Datepicker,
    expenseList,
    DatetimePickerStartEnd,
    crList,
  },
  data() {
    return {
      show_po: window.localStorage.getItem("isPurchaseOrder"),
      allExp: [],
      loginUserRole: window.localStorage.getItem("UserRole"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: new Date(),
        to: null,
      },
      category_value: [],
      isActiveFirst: true,
      submitStatus: true,
      CurrentPage: 1,
      totalPages: 0,
      toDate: "",
      fromDate: "",
      po_list: [],
      cr_list: [],
      tabIndexCheck: 0,
      toDateDisable: true,
      projects: [],
      users: [],
      vendors: [],
      pancardList: [],
      VHCList: [],
      tempVHCList: [],
      statuses: [
        { status_name: "Approved", status_id: 1 },
        { status_name: "Rejected", status_id: 2 },
        { status_name: "Pending", status_id: "0" },
      ],
      VerifiedStatus: [
        { status_name: "Verified", verify_id: 1 },
        { status_name: "Not Verified", verify_id: 0 },
      ],
      priority: [
        { priority_name: "Urgent", priority_id: 1 },
        { priority_name: "Regular", priority_id: 2 },
      ],
      projects_option: [],
      users_option: [],
      vendor_options: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null,
      statuses_option: [],
      verified_status: [],
      priority_option: [],
      expense: [],
      form: {
        project_id: "",
        user_id: "",
        vendor_id: "",
        invoice_number: "",
        category_id: "",
        status_id: "",
        verify_id: "",
        dateFrom: "",
        dateTo: "",
        vendorPan: "",
        default_alt_org_id: "",
        vhcCode: "",
        shootLocation: "",
        payment_due_date: "",
        tallyPaid: "",
        fixvar: "",
        budget_code: "",
      },
      shootLocationList: [],
      alternateOrg: [],
      tallyTypeList: [
        {
          syncId: "0",
          syncType: "Paid",
        },
        {
          syncId: "1",
          syncType: "Unpaid",
        },
      ],
      fixvarList: [
        {
          id: "0",
          type: "Fixed",
        },
        {
          id: "1",
          type: "Variable",
        },
      ],
      budgetCodesList: ["ATL", "BTL", "MUSIC", "OTHER", "POST", "VFX"],
      budgetCodes: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
          width: 275,
          filter: true,
        },
        {
          headerName: "User",
          field: "user_name",
          filter: true,
          width: 275,
        },
        {
          headerName: "Category",
          field: "category_name",
          filter: true,
          width: 375,
        },
        {
          headerName: "Sub Category",
          field: "sub_category_name",
          filter: true,
          width: 375,
        },
        {
          headerName: "Amount (₹)",
          field: "total",
          filter: true,
          width: 200,
        },
        {
          headerName: "Status",
          field: "purchase_status",
          filter: false,
          width: 150,
          cellRenderer: function(params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            }
          },
        },
        {
          headerName: "Download",
          field: "",
          filter: false,
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function(params) {
            return '<span class="text-center table-edit-icon"><i class="text-center material-icons">save_alt</i></span>';
          },
        },
      ],
      isTally: "",
      permissionPageAccess: "",
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] ||
              newVal[x] !== undefined ||
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
    tabIndexCheck: {
      handler: function(newVal, oldVal) {
        if (this.form.project_id != "") {
          this.CurrentPage = 1;
          this.submitForm();
        }
      },
      deep: true,
    },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    allExpense: function(payload) {
      delete payload.page_no;

      expenseService.getExpenseByFilter(payload).then((response) => {
        const { data } = response;
        if (data.Status == true || data.Status == "true") {
          this.allExp = data.data;
        }
      });
    },
    getBudgetCodeList: function(projectId) {
      BudgetService.getBudgetCodes(projectId)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            if (data.data.length > 0) {
              this.budgetCodes = data.data;
            } else {
              this.budgetCodes = this.budgetCodesList;
            }
          } else {
            this.$vs.notify({
              time: 5000,
              title: "Data Not Found",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == true) {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSelectDate: function($event) {
      this.toDateDisable = false;
      this.endDisabledDates["to"] = new Date($event);
      this.endDisabledDates["from"] = new Date();
      this.form.dateTo = "";
    },

    getUsersList: function(id, user_id) {
      this.users = [];
      let payload = {
        id: id,
        user_id: 121,
      };
      UserService.getProjectUserList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.users = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getProjectsList: function() {
      this.projects = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("3P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }

            if (data.alternateOrgNew.length > 0) {
              this.alternateOrg = data.alternateOrgNew;
            } else {
              this.alternateOrg = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getVendorsList: function() {
      this.vendors = [];
      this.pancardList = [];
      ContractService.getContractVendorNew("", null, "dashboardFilterVendor")
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.vendors = data.data;

            data.data &&
              data.data.map((x) => {
                if (x.vendor_pan && x.vendor_pan != null) {
                  this.pancardList.push(x);
                }
              });

            this.VHCList = data.vhc_data;
            this.tempVHCList = data.vhc_data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCategory: function(id) {
      this.categoryValue = null;
      this.subcategoryValue = null;
      let projectID = id.project_id;
      this.getCategoryList(projectID);
      this.getBudgetCodeList(projectID);
      this.getUsersList(projectID);

      let projectVHC = projectID
        ? this.tempVHCList.filter((x) => {
            return x.project_id == projectID;
          })
        : [];
      if (projectVHC.length > 0) {
        this.VHCList = projectVHC;
      }
    },
    getCategoryList: function(projectId) {
      CategoryService.getProjectMainCategories(projectId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message || "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onSuggestSelect: function(selected) {
      (this.subcategoryValue = null),
        this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function(ID) {
      let pId = this.projects_option.project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            if (data.data.length == 1) {
              this.subcategoryValue = this.subcategories[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message || "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message || "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.projects_option = [];
      this.users_option = [];
      this.vendor_options = [];
      (this.categoryValue = null),
        (this.subcategoryValue = null),
        (this.statuses_option = []);
      this.verified_status = [];
      this.priority_option = [];
      this.$validator.reset();
      this.submitStatus = true;
      this.expense = [];
    },

    submitForm(e) {
      this.expense = [];
      this.$validator.validateAll().then((result) => {
        if (this.form.dateFrom) {
          if (!this.form.dateTo) {
            this.$vs.notify({
              title: "Error!",
              text: "select To date",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return false;
          }
        }

        if (this.categoryValue) {
          if (!this.subcategoryValue) {
            this.$vs.notify({
              title: "",
              text: "Select Subcategory to filter",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return false;
          }
        }

        this.$vs.loading();
        if (result) {
          let fromDate = moment(this.form.dateFrom).format("YYYY-MM-DD");
          let toDate = moment(this.form.dateTo);
          this.fromDate = moment(this.form.dateFrom).format("YYYY-MM-DD");
          this.toDate = moment(this.form.dateTo)
            .add(1, "d")
            .format("YYYY-MM-DD");

          if (fromDate == "Invalid date") {
            fromDate = "";
            this.fromDate = "";
          }
          if (toDate == "Invalid date") {
            toDate = "";
            this.toDate = "";
          }
          this.getFilterExpList();
        }
      });
    },
    getFilterExpList: function() {
      let payload = {
        projectId: this.form.project_id,
        userId: this.form.user_id,
        vendorId: this.form.vendor_id,
        invoiceNo: this.form.invoice_number,
        categoryId: this.form.category_id,
        purchaseStatus: this.form.status_id,
        verifyStatus: this.form.verify_id,
        priorityStatus: this.form.priority_id,
        locationId: this.form.shootLocation
          ? this.form.shootLocation.location_id
          : "",
        tallyPaid: this.form.tallyType ? this.form.tallyType.syncId : "",
        payableDueDate: this.form.payment_due_date
          ? moment(this.form.payment_due_date).format("YYYY-MM-DD")
          : "",
        dateFrom: this.fromDate,
        dateTo: this.toDate,
        search: this.searchQuery,
        type: this.tabIndexCheck,
        page_no: this.CurrentPage,
        VHC_code: this.form.vhcCode.hasOwnProperty("VHC_code")
          ? this.form.vhcCode.VHC_code
          : "",
        vendor_pan: this.form.vendorPan.hasOwnProperty("vendor_pan")
          ? this.form.vendorPan.vendor_pan
          : "",
        default_alt_org_id: this.form.default_alt_org_id.hasOwnProperty(
          "default_alt_org_id"
        )
          ? this.form.default_alt_org_id.default_alt_org_id
          : "",

        fixvar: this.form.fixvar ? this.form.fixvar.type : "",
        budget_code: this.form.budget_code ? this.form.budget_code : "",
      };
      expenseService
        .getExpenseByFilter(payload)
        .then((response) => {
          this.allExp = [];
          this.expense = [];
          this.po_list = [];
          this.cr_list = [];

          this.allExpense(payload);

          this.$vs.loading.close();
          const { data } = response;
          if (this.form.dateFrom) {
            if (data.data.length <= 0) {
              this.$vs.notify({
                title: "No Expense/PO recorded for this date range",
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          }
          if (data.Status == true || data.Status == "true") {
            if (this.tabIndexCheck == 1) {
              this.po_list = data.data;
            } else if (this.tabIndexCheck == 2) {
              this.cr_list = data.data;
            } else {
              this.expense = data.data;
            }
            this.totalPages = data.pagination.last_page;
            this.$forceUpdate();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getProjectsList();
    this.getVendorsList();
    eventBus.$on("getPageNumber", (e) => {
      let count = 1;
      if (this.form.project_id == "") {
        count = 0;
      }
      this.CurrentPage = e;
      if (count == 1) {
        this.submitForm(e);
      }
    });
  },
  beforeMount() {
    this.getShootLocationList();
    this.isTally = localStorage.getItem("isTally");
    this.permissionPageAccess = localStorage.getItem("permissionPageAccess")
      ? localStorage.getItem("permissionPageAccess").split(",")
      : "";
    this.organization_id = localStorage.getItem("OrganizationID");
  },
};
</script>
