<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Petty Cash Request</h4>
          <form>
            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Project <span class="text-danger">*</span></h6>
                <multiselect
                  v-validate="'required'"
                  v-model="form.projectName"
                  track-by="project_id"
                  label="project_name"
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  @select="onProjectSelect"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                  >
                    {{ errors.first("Project Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2 mx-auto">
                <h6>Impressed User <span class="text-danger">*</span></h6>
                <vue-simple-suggest
                  @input="checkPcrVendor"
                  v-validate="'required'"
                  :min-length="0"
                  v-model="form.vendorName"
                  :list="vendorList"
                  display-attribute="vendor_name"
                  value-attribute="vendor_id"
                  :filter-by-query="true"
                  name="Vendor Name"
                  autocomplete="off"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                  >
                    {{ errors.first("Vendor Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Approver <span class="text-danger">*</span></h6>
                <multiselect
                  @remove="toggleUnSelectMarket"
                  v-validate="'required'"
                  label="user_name"
                  class="w-full"
                  track-by="user_name"
                  v-model="form.approver_id"
                  name="Approver Name"
                  :options="ApproverList"
                  :searchable="true"
                  :disabled="
                    (form.req_user == '' ? true : false) && showOnBehalf
                  "
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  :multiple="true"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Approver Name')"
                  >
                    {{ errors.first("Approver Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Country <span class="text-danger">*</span></h6>
                <multiselect
                  label="country_name"
                  class="w-full"
                  track-by="country_id"
                  v-model="form.countryValue"
                  name="Priority"
                  :options="countryList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  @select="countrySelected"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2l">
                <h6>City <span class="text-danger">*</span></h6>
                <multiselect
                  :options="cityList"
                  v-model="form.cityValue"
                  track-by="city_id"
                  label="city_name"
                  placeholder="City Name"
                  name="City Name"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('City Name')"
                  >
                    {{ errors.first("City Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Payment Due Date (Optional)</h6>
                <datepicker
                  placeholder="To Date"
                  :disabledDates="endDisabledDates"
                  v-model="form.payment_due_date"
                  format="dd-MM-yyyy"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Payment Due Date')"
                  >
                    {{ errors.first("Payment Due Date") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Category (Optional)</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                  >
                    {{ errors.first("Category Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Subcategory (Optional)</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                  >
                    {{ errors.first("Subcategory Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Set Name (Optional)</h6>
                <multiselect
                  label="location_name"
                  class="w-full"
                  track-by="location_id"
                  v-model="form.shootLocation"
                  name="location_name"
                  :options="shootLocationList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Currency <span class="text-danger">*</span></h6>
                <multiselect
                  v-validate="'required'"
                  label="currency_code"
                  class="w-full"
                  track-by="currency_id"
                  v-model="form.currency"
                  name="Currency"
                  :options="currencyList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Currency')"
                  >
                    {{ errors.first("Currency") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Amount <span class="text-danger">*</span></h6>
                <vs-input
                  v-validate="'required'"
                  type="number"
                  name="Amount"
                  @input="totalAmount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                  >
                    {{ errors.first("Amount") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>TDS (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="!form.amount"
                      label="tds_name"
                      class="w-full"
                      track-by="tds_id"
                      v-model="form.tds_id"
                      name="TDS"
                      @select="tdsCalculate"
                      :options="tdsList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('TDS')"
                      >
                        {{ errors.first("TDS") }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="tds_name != 'Manual Amount'"
                      type="number"
                      name="TDS"
                      v-model="form.tds_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Total Amount</h6>
                <vs-input
                  type="number"
                  name="GST"
                  v-model="form.total_amount"
                  class="w-full"
                  placeholder=""
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>

              <div
                class="col-12 col-md-4 col-sm-4 mb-2"
                v-if="showOnBehalf == true"
              >
                <h6>Request by</h6>
                <multiselect
                  label="user_name"
                  class="w-full"
                  track-by="user_name"
                  v-model="form.req_user"
                  name="Request by"
                  :options="BehalfOfList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  @select="onSelectBehalf"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Request by')"
                  >
                    {{ errors.first("Request by") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <h6>Comment <span class="text-danger">*</span></h6>
                <vs-textarea
                  v-validate="'required'"
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                  >
                    {{ errors.first("Comment") }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-md-6 mb-2 doc-upload">
                <h6>Upload Document (Optional)</h6>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptionsFiles"
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-success-multiple="vdropzoneSuccess"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus == true"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="mt-2 feather icon-trash"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus == true"
                  type="filled"
                  @click.prevent="openCropper()"
                  class="ml-4 mt-2"
                >
                  Crop Images
                </vs-button>
              </div>
              <div v-if="showCropperModal == true">
                <image-cropper :allSelectedImages="allSelectedImages" />
              </div>
            </div>
          </form>

          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mr-3 mb-3 w-full sm:w-auto"
            >
              Submit
            </vs-button>

            <vs-button
              color="warning"
              type="border"
              class=" mb-3 w-full sm:w-auto"
              @click.prevent="clearForm"
            >
              Reset
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import VueSimpleSuggest from "vue-simple-suggest";
import ImageCropper from "../projects/imageCropper.vue";
import UserService from "@/services/UserService.js";
import MethodService from "@/services/methodService.js";
import CategoryService from "@/services/categoryService.js";
import CurrencyService from "@/services/currencyService.js";
import CountryService from "@/services/countryService.js";
import projectService from "@/services/projectService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import CashManagementService from "@/services/CashManagementService.js";
import ContractService from "@/services/ContractService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
    projects: Array,
    currencyList: Array,
    shootLocationList: Array,
    alternateOrg: Array,
    countryList: Array,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSimpleSuggest,
    ImageCropper,
  },
  beforeMount() {
    this.getMasterList();
    this.getImpressedUsers();
    this.organization_id = localStorage.getItem("OrganizationID");

    this.dropzoneOptionsFiles["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      showCropperModal: false,
      disablePo: false,
      tempVendor: false,
      allSelectedImages: [],
      showOnBehalf: false,
      userApprovedList: [],
      userList: [],
      isAlreadyPaid: false,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      ApproverList: [],
      BehalfOfList: [],
      paymentMode: [],
      categoriesByProject: [],
      organization_id: "",
      submitStatus: true,
      tdsList: [],
      tds_name: "",
      form: {
        projectName: null,
        vendorName: "",
        categoryValue: null,
        subcategoryValue: null,
        shootLocation: "",
        countryValue: null,
        cityValue: null,
        // default_alt_org_id: "",
        currency: "",
        amount: "",
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        comment: "",
        payment_due_date: "",
        approver_id: "",
        req_user: {
          user_id: window.localStorage.getItem("user_id"),
          user_name: window.localStorage.getItem("UserName"),
        },
        modeOfPayment: "",
      },
      categoryList: [],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles:
          ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp,.doc,.docx",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      uploadedDocumentArray: [],
      cityList: [],
      vendorList: [],
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        setTimeout(() => {
          if (!this.form.categoryValue) {
            delete this.form.categoryValue;
          }
          if (!this.form.subcategoryValue) {
            delete this.form.subcategoryValue;
          }
        }, 1000);

        if (!this.form.shootLocation) {
          delete this.form.shootLocation;
        }

        if (!this.form.tds_id) {
          delete this.form.tds_id;
        }
        if (!this.form.tds_amount) {
          delete this.form.tds_amount;
        }

        if (!this.form.payment_due_date) {
          delete this.form.payment_due_date;
        }

        let len = Object.keys(this.form).length - 1;
        if (
          this.tempVendor == true &&
          len <=
            Object.keys(newVal).filter(
              (x, index) =>
                newVal[x] &&
                newVal[x] !== undefined &&
                String(newVal[x]).trim().length > 0
            ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);

    this.endDisabledDates["to"] = d;
    eventBus.$on("UploadCropedImage", (payload) => {
      this.$refs.myVueDropzone.removeFile(payload.fileToRemove);
      this.$refs.myVueDropzone.addFile(payload.fileToAdd);
    });

    setTimeout(() => {
      if (this.countryList) {
        this.form.countryValue = this.countryList[0];
        this.countrySelected(this.countryList[0]);
      }
    }, 1000);
  },
  methods: {
    onProjectSelect: function(selectedOption, id) {
      this.form.categoryValue = {};
      this.form.subcategoryValue = {};
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
      this.getDataByUserAndProject(
        this.project_id,
        window.localStorage.getItem("user_id")
      );
      if (this.form.req_user.user_id) {
        this.getApproverList(
          selectedOption.project_id,
          this.form.req_user.user_id
        );
      }
      // if (this.form.cityValue) {
      //   this.getCategory(this.form.cityValue);
      // }
    },

    countrySelected(country) {
      this.form.cityValue = {};
      this.cityList = [];
      CountryService.getCityByCountry(country.country_id)
        .then((response) => {
          if (response.data.Status) {
            this.cityList = response.data.data;
          }
        })
        .catch((error) => console.log("Error =>", error.message));
    },

    tdsCalculate(selected) {
      this.tds_name = selected.tds_name;
      this.tds_percentage = selected.tds_percentage;
      this.form.tds_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(2) : 0;

      let totalAmount = (parseFloat(selected.tds_percentage) * amount) / 100;
      this.form.tds_amount = parseFloat(totalAmount);
      this.totalAmount();
    },

    totalAmount() {
      let amount = this.form.amount != "" ? parseFloat(this.form.amount) : 0;
      this.form.total_amount =
        (parseFloat(amount) * 10 -
          (this.form.tds_amount ? parseFloat(this.form.tds_amount) : 0) * 10) /
        10;
    },

    checkPcrVendor() {
      let vendorCheck = this.vendorList.filter((x) => {
        return x.vendor_name == this.form.vendorName;
      });
      if (vendorCheck.length > 0) {
        this.tempVendor = true;
      } else {
        this.tempVendor = false;
      }
    },
    toggleUnSelectMarket(value) {
      if (value.default_approver == "1") {
        this.form.approver_id.push(value);
      }
    },
    openCropper() {
      this.showCropperModal = true;
    },
    getDataByUserAndProject(project_id, user_id) {
      projectService
        .getDataByUserAndProject(project_id, user_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.showOnBehalf =
              data.data[0].is_moderator_user ||
              data.data[0].is_admin_manager_user ||
              window.localStorage.getItem("UserRole") == "Admin"
                ? true
                : false;
          }
        })
        .catch((error) => {
          console.error("error :", error);
        });
    },
    getMasterList: function() {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.paymentMode = data.data.payment_mode;
            this.tdsList = data.data.tds;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSuggestSelect: function(selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },
    onSelectBehalf(selected) {
      this.form.approver_id = "";
      this.getApproverList(this.form.projectName.project_id, selected.user_id);
    },

    getAllCategoryListByProject: function(id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == true) {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCurrencyList: function() {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.currencyList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function() {
      this.projects = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // categoryList: function(selectedOption, id) {
    //   this.project_id = selectedOption.project_id;
    //   this.getAllCategoryListByProject(this.project_id);
    //   this.getMapList(this.project_id);
    //   this.getDataByUserAndProject(
    //     this.project_id,
    //     window.localStorage.getItem("user_id")
    //   );
    //   if (this.form.req_user.user_id) {
    //     this.getApproverList(
    //       selectedOption.project_id,
    //       this.form.req_user.user_id
    //     );
    //   }
    // },

    getMapList: function(id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.BehalfOfList = data.data;
            this.userApprovedList = this.BehalfOfList;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getApproverList: function(id, user_id) {
      let payload = {
        id: id,
        user_id: user_id,
      };
      UserService.getApproverList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.ApproverList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    submitForm: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let approver_id = "0";
          if (this.form.approver_id.length > 0) {
            approver_id = "";
            this.form.approver_id.map((item) => {
              approver_id += item.user_id + ",";
            });

            var lastChar = approver_id.slice(-1);
            if (lastChar == ",") {
              approver_id = approver_id.slice(0, -1);
            }
          }
          let vendor_id = "";

          let payload = {
            project_id: this.form.projectName.project_id,
            po_name: this.form.vendorName,
            item_name: "-",
            company_name: "-",
            category_id: this.form.subcategoryValue
              ? this.form.subcategoryValue.category_id
              : null,
            customFieldValues: [],
            company_address: "-",
            price: this.form.amount,
            currency: this.form.currency
              ? this.form.currency.currency_code
              : "",
            purchase_image: this.uploadedDocument,
            payment_mode: this.form.modeOfPayment
              ? this.form.modeOfPayment.payment_mode_id
              : "",
            sgst: "0",
            cgst: "0",
            tds_id: this.form.tds_id ? this.form.tds_id.tds_id : 0,
            tds_amount: this.form.tds_amount ? this.form.tds_amount : 0,
            total: this.form.amount,
            total_amount: this.form.total_amount,
            purchase_description: this.form.comment,
            location_id: this.form.shootLocation
              ? this.form.shootLocation.location_id
              : "",
            default_alt_org_id: this.form.default_alt_org_id
              ? this.form.default_alt_org_id.default_alt_org_id
              : "",

            purchase_status: "0",
            approved_percentage: "0",
            purchase_active: "1",
            is_var: "3",
            payment_mode_description: "-",
            purchase_type: "cash_request",
            approver_id: approver_id,
            payment_due_date: this.form.payment_due_date
              ? this.form.payment_due_date
              : "",
            vendorName: this.form.vendorName,
            vendor_id: vendor_id,
            onBehalfId: this.form.req_user ? this.form.req_user.user_id : 0,
            country_id: this.form.countryValue.country_id || null,
            city_id: this.form.cityValue.city_id || null,
          };
          let vendorCheck = this.vendorList.filter((x) => {
            return x.vendor_name == this.form.vendorName;
          });
          if (vendorCheck.length > 0) {
            payload["vendor_id"] = vendorCheck[0].vendor_id;
          }
          // payload.lineItemData = this.form.subcategoryValue
          //   ? this.form.subcategoryValue.map((item) => ({
          //       category_id: item.category_id,
          //       unit: item.unit,
          //       rate: item.rate,
          //       amount: item.amount,
          //       total: item.total,
          //       description: item.description,
          //     }))
          //   : [];

          CashManagementService.addCashRequest(payload)
            .then((response) => {
              const { data } = response;

              this.$vs.loading.close();
              if (data.Status == true) {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
              }
              this.clearForm();
            })
            .catch((error) => {
              this.$vs.notify({
                title: "Error!",
                text: error.message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
        }
      });
    },

    getSubcategoryList: function(ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.subcategoryList = data.data;
            if (data.data.length == 1) {
              this.form.subcategoryValue = this.subcategoryList[0];
            } else {
              this.form.subcategoryValue = "";
            }
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              if (subcategoryArray.length > 0) {
                this.form.subcategoryValue = subcategoryArray[0];
              }
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneSuccessFile: function(file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },
    vdropzoneSuccess: function(file, response) {
      this.user_pic = response.data.image_path;
      this.uploadedImage = response.data.image_path;
      this.uploadedDocumentArray.push(response.data.image_path);
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      this.imageUpload.deleteButtonStatus = true;
      this.fileUpload.deleteButtonStatus = true;
    },
    vdropzoneComplete: function(response) {
      for (let i = 0; i < response.length; i++) {
        this.selectedFilesUUID.push(response[i].upload.uuid);
        if (!response[i].cropped) {
          this.allSelectedImages.push(response[i]);
        }
      }
      this.showCropperModal = false;
      this.fileUpload.deleteButtonStatus = true;
    },
    clearFile: function() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.allSelectedImages = [];

      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
    },
    vremoved: function(file) {
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
    },

    clearForm: function() {
      this.form = {
        vendorName: "",
        categoryValue: "",
        shootLocation: "",
        currency: "",
        amount: "",
        comment: "",
        payment_due_date: "",
        approver_id: "",
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        req_user: {
          user_id: window.localStorage.getItem("user_id"),
          user_name: window.localStorage.getItem("UserName"),
        },
        modeOfPayment: "",
        subcategoryValue: "",
      };
      this.reason = "";
      this.submitStatus = true;
      this.uploadedDocument = "-";
      this.clearFile();
      this.$validator.reset();
    },

    // getCategory(city) {
    //   this.form.categoryValue = null;
    //   this.form.subcategoryValue = null;
    //   if (!this.form.projectName) return;
    //   CategoryService.getProjectMainCategoriesByCity({
    //     project_id: this.form.projectName.project_id,
    //     city_id: city.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.categoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },

    // getSubCategory(category) {
    //   CategoryService.getProjectSubCategoriesByParentAndCity({
    //     parent_id: category.category_id,
    //     project_id: this.form.projectName.project_id,
    //     city_id: this.form.cityValue.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.subcategoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },

    getImpressedUsers() {
      ContractService.getContractVendorNew({ is_impressed: 1 })
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "Warning!",
              text: response.data.Message || "Impressed Users Not Found!",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.vendorList = response.data.data;
        })
        .catch((error) => console.log("Error => ", error.message));
    },
  },
};
</script>
