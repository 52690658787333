<template>
  <vx-card>
    <vs-tabs>
      <vs-tab label="User PO">
        <vs-tabs>
          <vs-tab label="Pending" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="0" />
          </vs-tab>
          <vs-tab label="Approved" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="1" />
          </vs-tab>
          <vs-tab label="Rejected" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="2" />
          </vs-tab>
        </vs-tabs>
      </vs-tab>
      <vs-tab label="My PO">
        <vs-tabs>
          <vs-tab label="Pending" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="0" isMyPO />
          </vs-tab>
          <vs-tab label="Approved" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="1" isMyPO />
          </vs-tab>
          <vs-tab label="Rejected" icon-pack="feather" icon="icon-box">
            <ExpenseListNew :expenseStatus="2" isMyPO />
          </vs-tab>
        </vs-tabs>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import ExpenseListNew from "./ExpenseListNew.vue";
export default {
  components: { ExpenseListNew },
};
</script>
