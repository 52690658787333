<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.CreateExpenses
              ) || loginUserRole == 'Admin'
            "
            label="Create Expense"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <create-expense-view />
            </div>
          </vs-tab>
          <vs-tab label="Expense List" icon-pack="feather" icon="icon-box">
            <ExpensesView />
          </vs-tab>
          <vs-tab label="Deleted List" icon-pack="feather" icon="icon-box">
            <DeletedList />
          </vs-tab>
          <!-- <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.ViewProjectExpense
              ) || loginUserRole == 'Admin'
            "
            label="View Expenses"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <expenses />
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import createExpenseView from "@/views/apps/expenses/createExpenseView.vue";
import expenses from "@/views/apps/expenses/expenses.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import ExpensesView from "./ExpensesView.vue";
import DeletedList from "./DeletedList.vue";

export default {
  components: {
    createExpenseView,
    expenses,
    ExpensesView,
    DeletedList,
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
    };
  },
};
</script>
