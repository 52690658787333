<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Create Expense</h4>
          <form>
            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Project <span class="text-danger">*</span></h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  autocomplete="off"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                  >
                    {{ errors.first("Project Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2 mx-auto">
                <h6>Vendor <span class="text-danger">*</span></h6>
                <vue-simple-suggest
                  @input="setVar"
                  :min-length="0"
                  v-model="form.vendorName"
                  :list="vendorList"
                  display-attribute="vendor_name"
                  value-attribute="vendor_id"
                  :filter-by-query="true"
                  name="Vendor Name"
                  v-on:blur="checkVendor"
                  v-on:focus="vendor_flag = true"
                  autocomplete="off"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                  >
                    {{ errors.first("Vendor Name") }}
                  </span>
                </p>
                <p v-if="isVarRequestApplicable > 0 && !vendornotfound">
                  <vs-chip color="success">
                    VAR Balance: <b>{{ isVarRequestApplicable }}</b>
                  </vs-chip>
                </p>
                <p v-if="isPcrRequestApplicable > 0 && !vendornotfound">
                  <vs-chip color="success">
                    PCR Balance: <b>{{ isPcrRequestApplicable }}</b>
                  </vs-chip>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Invoice Number <span class="text-danger">*</span></h6>
                <vs-input
                  autocomplete="off"
                  type="text"
                  name="Invoice Number"
                  v-model="form.invoice_number"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Invoice Number')"
                  >
                    {{ errors.first("Invoice Number") }}
                  </span>
                  <span class="text-danger text-sm" v-if="dupinvoice != ''">
                    {{ dupinvoice }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Invoice Date (Optional)</h6>
                <datepicker
                  placeholder="From Date"
                  @selected="onSelectDate($event)"
                  format="dd-MM-yyyy"
                  v-model="form.invoice_date"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Invoice Date')"
                  >
                    {{ errors.first("Invoice Date") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Payment Due Date (Optional)</h6>
                <datepicker
                  placeholder="To Date"
                  :disabledDates="endDisabledDates"
                  v-model="form.payment_due_date"
                  format="dd-MM-yyyy"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Payment Due Date')"
                  >
                    {{ errors.first("Payment Due Date") }}
                  </span>
                </p>
              </div>

              <div
                class="col-12 col-md-4 col-sm-4 mb-2"
                v-if="
                  isShootLocationActive == true ||
                    isShootLocationActive == 'true'
                "
              >
                <h6>Set Name (Optional)</h6>
                <multiselect
                  label="location_name"
                  class="w-full"
                  track-by="location_id"
                  v-model="form.shootLocation"
                  name="location_name"
                  :options="shootLocationList"
                  :searchable="true"
                  :allow-empty="true"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('location_name')"
                  >
                    {{ errors.first("location_name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Country <span class="text-danger">*</span></h6>
                <multiselect
                  label="country_name"
                  class="w-full"
                  track-by="country_id"
                  v-model="form.countryValue"
                  name="Priority"
                  :options="countryList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  @select="countrySelected"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2l">
                <h6>City <span class="text-danger">*</span></h6>
                <multiselect
                  :options="cityList"
                  v-model="form.cityValue"
                  track-by="city_id"
                  label="city_name"
                  placeholder="City Name"
                  name="City Name"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('City Name')"
                  >
                    {{ errors.first("City Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Priority <span class="text-danger">*</span></h6>
                <multiselect
                  label="priority_name"
                  class="w-full"
                  track-by="priority_id"
                  v-model="form.priorityValue"
                  name="Priority"
                  :options="priority"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Category <span class="text-danger">*</span></h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                  >
                    {{ errors.first("Category Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Subcategory <span class="text-danger">*</span></h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                  >
                    {{ errors.first("Subcategory Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2" v-if="showOnBehalf">
                <h6>On Behalf Of</h6>
                <multiselect
                  label="user_name"
                  class="w-full"
                  track-by="user_id"
                  v-model="form.onBehalfId"
                  name="Approver"
                  :options="approverList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Approver')"
                  >
                    {{ errors.first("Approver") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Mode of payment <span class="text-danger">*</span></h6>
                <multiselect
                  :disabled="form.raised_from_pcr == true ? true : false"
                  label="payment_mode_name"
                  class="w-full"
                  track-by="payment_mode_id"
                  v-model="form.modeOfPayment"
                  name="Mode of payment"
                  :options="paymentMode"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Mode of payment')"
                  >
                    {{ errors.first("Mode of payment") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Amount <span class="text-danger">*</span></h6>
                <vs-input
                  type="number"
                  name="Amount"
                  @input="totalAmount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                  >
                    {{ errors.first("Amount") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Tax (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="form.amount == 0"
                      label="gst_name"
                      class="w-full"
                      track-by="gst_id"
                      v-model="form.gst_id"
                      name="gst"
                      @select="gstCalculate"
                      :options="gstList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Priority')"
                      >
                        {{ errors.first("Priority") }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="gst_name != 'Manual Amount'"
                      type="number"
                      name="GST"
                      v-model="form.gst_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>TDS (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="!form.amount"
                      label="tds_name"
                      class="w-full"
                      track-by="tds_id"
                      v-model="form.tds_id"
                      name="TDS"
                      @select="tdsCalculate"
                      :options="tdsList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('TDS')"
                      >
                        {{ errors.first("TDS") }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="tds_name != 'Manual Amount'"
                      type="number"
                      name="TDS"
                      v-model="form.tds_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 col-sm-4 mb-2">
                <h6>Total Amount</h6>
                <vs-input
                  type="number"
                  name="GST"
                  v-model="form.total_amount"
                  class="w-full"
                  placeholder=""
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                  >
                    {{ errors.first("Priority") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <h6>Comment <span class="text-danger">*</span></h6>
                <vs-textarea
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                  >
                    {{ errors.first("Comment") }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-md-6 mb-2 doc-upload">
                <h6>Upload Document <span class="text-danger">*</span></h6>
                <vue-dropzone
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-error-multiple="vdropzoneError"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-added-files="vdropzoneFilesAdded"
                  @vdropzone-success-multiple="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="feather icon-trash mt-2"
                />
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="openCropper()"
                  class="ml-4 mt-2"
                >
                  Crop Images
                </vs-button>
              </div>
              <div class="col-4 mb-2" style="display: none">
                <h6>Already Approved</h6>
                <vs-switch v-model="isAlreadyPaid" />
              </div>
              <div
                v-if="isVarRequestApplicable > 0 && !vendornotfound"
                class="col-8 mb-2"
              >
                <vs-checkbox
                  :disabled="form.raised_from_pcr == true"
                  val="1"
                  v-model="form.raised_from_var"
                >
                  Expense Against Vendor Advance Request
                </vs-checkbox>
              </div>

              <div
                v-if="isPcrRequestApplicable > 0 && !vendornotfound"
                class="col-8 mb-2"
              >
                <vs-checkbox
                  @click="changePaymentMode"
                  :disabled="form.raised_from_var == true"
                  val="2"
                  v-model="form.raised_from_pcr"
                >
                  Expense Against Petty Cash
                </vs-checkbox>
              </div>
            </div>
          </form>

          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="
                this.uploadedDocument == '-' || dupinvoice != ''
                  ? true
                  : submitStatus
              "
              type="filled"
              @click.prevent="submitForm"
              class="mr-3 mb-3 w-full sm:w-auto"
            >
              Submit
            </vs-button>
            <vs-button
              color="warning"
              type="border"
              class=" mb-3 w-full sm:w-auto"
              @click.prevent="clearForm"
            >
              Reset
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <vs-popup
      id="popmodalCrop"
      class="popmodalViewR"
      title="Crop Image"
      :active.sync="popupCropImg"
    >
      <div class="vx-row mt-5">
        <div
          class="vx-col"
          :key="imageKey"
          v-for="(image, imageKey) in allSelectedImages"
        >
          <img
            @click="getImageToCrop(image)"
            v-if="image.dataURL"
            :src="image.dataURL"
            width="150"
            height="150"
          />
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col mx-auto">
          <vue-cropper
            :img-style="{ width: '400px', height: '300px' }"
            v-if="showCropper == true"
            ref="cropper"
            :src="selectedImageForCrop.dataURL"
          />
          <vs-button
            @click="getCropedImage"
            color="success"
            type="filled"
            class="mt-3"
          >
            Crop
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VueSimpleSuggest from "vue-simple-suggest";
import vue2Dropzone from "vue2-dropzone";
import VueCropper from "vue-cropperjs";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import CountryService from "@/services/countryService.js";
import UserService from "@/services/UserService.js";
import ExpenseService from "@/services/expenseService.js";
import MethodService from "@/services/methodService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "cropperjs/dist/cropper.css";

export default {
  props: {
    formMouId: Number,
    projectList: Array,
    currencyList: Array,
    shootLocationList: Array,
    alternateOrg: Array,
    vendorList: Array,
    countryList: Array,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSimpleSuggest,
    VueCropper,
  },

  beforeMount() {
    this.getMasterList();
    this.organization_id = localStorage.getItem("OrganizationID");
    this.dropzoneOptionsFiles["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      showOnBehalf: false,
      vendor_flag: false,
      vendornotfound: "",
      vendornotfoundold: 0,
      dupinvoice: "",
      isVarRequestApplicable: 0,
      isPcrRequestApplicable: 0,
      vendoridtemp: 0,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      submitStatus: true,
      isAlreadyPaid: false,
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      toDateDisable: true,
      gstList: [],
      tdsList: [],
      tds_name: "",
      form: {
        raised_from_var: null,
        raised_from_pcr: null,
        invoice_number: "",
        payment_due_date: "",
        invoice_date: "",
        gst_id: "",
        gst_amount: 0,
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        vendorName: "",
        categoryValue: null,
        amount: 0,
        comment: "",
        priorityValue: {},
        // default_alt_org_id: "",
        modeOfPayment: {},
        countryValue: {},
        cityValue: {},
        onBehalfId: window.localStorage.getItem("user_id"),
        shootLocation: "",
        subcategoryValue: null,
        lineItemData: [],
        projectName: {},
      },
      disablePo: false,
      popupCropImg: false,
      showCropper: false,
      selectedImageForCrop: null,
      priority: [],
      paymentMode: [],
      approverList: [],
      categoryList: [],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL + "/v2/multipleUploads?organization=",
        acceptedFiles:
          ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg,.webp,.doc,.docx",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      allSelectedImages: [],
      uploadedDocumentArray: [],
      gst_name: "",
      gst_percentage: 0,
      cityList: [],
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (
          newVal.categoryValue &&
          oldVal.categoryValue &&
          newVal.categoryValue.category_id != oldVal.categoryValue.category_id
        ) {
          this.form.subcategoryValue = [];
        }
        if (this.form.raised_from_pcr == true) {
          let paymentTypeForPCR = this.paymentMode.filter((x) => {
            return x.payment_mode_id == "1";
          });

          if (paymentTypeForPCR.length > 0) {
            this.form.modeOfPayment = paymentTypeForPCR[0];
          }
        }
        if (!this.form.invoice_number) {
          delete this.form.invoice_number;
        }
        if (!this.form.payment_due_date) {
          delete this.form.payment_due_date;
        }

        if (!this.form.raised_from_var) {
          delete this.form.raised_from_var;
        }
        if (!this.form.raised_from_pcr) {
          delete this.form.raised_from_pcr;
        }

        if (!this.form.invoice_date) {
          delete this.form.invoice_date;
        }
        if (!this.form.gst_id) {
          delete this.form.gst_id;
        }
        if (!this.form.gst_amount) {
          delete this.form.gst_amount;
        }
        if (!this.form.tds_id) {
          delete this.form.tds_id;
        }
        if (!this.form.tds_amount) {
          delete this.form.tds_amount;
        }
        if (!this.form.shootLocation) {
          delete this.form.shootLocation;
        }

        if (this.form.vendorName != "" && this.vendor_flag) {
          let vendorCheck = this.vendorList.filter((x) => {
            let ss1 = x.vendor_name;
            let ss2 = this.form.vendorName;
            ss1 = ss1.trim();
            ss2 = ss2.trim();

            let arr = ss1.split(ss2.toLowerCase());
            return arr.length > 0 ? true : false;
          });

          if (vendorCheck.length == 0) {
            if (this.form.vendorName != this.vendornotfoundold) {
              this.vendornotfoundold = this.form.vendorName;
              this.vendornotfoundold = this.vendornotfoundold.trim();
              this.vendornotfound =
                "Vendor named " + this.vendornotfoundold + " not found!";
            }
          } else {
            this.vendornotfound = "";
          }
        }

        this.dupinvoice = "";

        if (this.vendoridtemp > 0 && this.form.projectName) {
          this.isVarRequestApplicable = 0;
          this.isPcrRequestApplicable = 0;
          ExpenseService.checkInvoice(
            this.vendoridtemp,
            this.form.invoice_number,
            this.form.projectName.project_id
          )
            .then((response) => {
              const { data } = response;
              this.dupinvoice = data.Message;

              if (data.existingVarCount.varBalance) {
                this.isVarRequestApplicable = parseFloat(
                  data.existingVarCount.varBalance
                );
              } else {
                this.isVarRequestApplicable = 0;
              }

              if (data.user_balance_pcr) {
                this.isPcrRequestApplicable = parseFloat(data.user_balance_pcr);
              } else {
                this.isPcrRequestApplicable = 0;
              }
            })
            .catch((error) => {});
        }

        let len = Object.keys(this.form).length;

        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = false;
        }
      },
      deep: true,
    },

    /*
    "form.subcategoryValue": {
      handler(newVal, oldVal) {
        let amount = 0;
        this.form.subcategoryValue &&
          this.form.subcategoryValue.forEach((subCategory) => {
            if (
              subCategory &&
              subCategory.hasOwnProperty("unit") &&
              parseFloat(subCategory.unit) > 0 &&
              subCategory.hasOwnProperty("rate") &&
              parseFloat(subCategory.rate) > 0
            ) {
              subCategory.amount =
                parseFloat(subCategory.unit) * parseFloat(subCategory.rate);

              amount =
                (parseFloat(amount) * 10 +
                  parseFloat(subCategory.amount) * 10) /
                10;
            }
          });
        this.form.amount = amount;
        if (this.form.gst_id) this.gstCalculate(this.form.gst_id);
        this.totalAmount();
      },
      deep: true,
    },
    */
  },
  computed: {
    isCreditNote() {
      return 1;
    },
    isShootLocationActive() {
      return window.localStorage.getItem("isShootLocation");
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);

    this.endDisabledDates["to"] = d;
    if (this.isCreditNote == 0) {
      this.form.invoice_number = "1";
      this.form.payment_due_date = "1";
      this.form.invoice_date = "1";
      this.form.gst_id = "1";
      this.form.gst_amount = "1";
    }
    setTimeout(() => {
      if (this.countryList) {
        this.form.countryValue = this.countryList[0];
        this.countrySelected(this.countryList[0]);
      }
    }, 1000);
  },
  methods: {
    countrySelected(country) {
      this.form.cityValue = {};
      this.cityList = [];
      CountryService.getCityByCountry(country.country_id)
        .then((response) => {
          if (response.data.Status) {
            this.cityList = response.data.data;
          }
        })
        .catch((error) => console.log("Error =>", error.message));
    },
    storePaidFrom() {
      this.disablePo = true;
      this.vendorList = [];
      this.form.vendorName = "-";

      setTimeout(() => {
        eventBus.$emit(
          "setAmountPaidFrom",
          this.form.default_alt_org_id.default_alt_org_id
        );

        this.form.vendorName = "";
        this.disablePo = false;
      }, 1000);
    },
    changePaymentMode() {
      if (this.form.raised_from_pcr == true) {
        let paymentTypeForPCR = this.paymentMode.filter((x) => {
          return x.payment_mode_id == "1";
        });

        if (paymentTypeForPCR.length > 0) {
          this.form.modeOfPayment = paymentTypeForPCR[0];
        }
      }
    },
    setVar() {},
    checkVendor() {
      this.vendor_flag = false;
      if (this.form.vendorName != "") {
        let vendorCheck = this.vendorList.filter((x) => {
          let ss1 = x.vendor_name;
          let ss2 = this.form.vendorName;
          ss1 = ss1.trim();
          ss2 = ss2.trim();
          return ss1.toLowerCase() == ss2.toLowerCase();
        });

        if (vendorCheck.length == 0) {
          if (this.form.vendorName != this.vendornotfoundold) {
            this.vendornotfoundold = this.form.vendorName;

            this.vendornotfoundold = this.vendornotfoundold.trim();
            this.vendornotfound =
              "Vendor named " + this.vendornotfoundold + " not found!";
          }
        } else {
          this.vendoridtemp = vendorCheck[0].vendor_id;
        }
      }

      let tempinvoice = this.form.invoice_number + "";

      let leninvoice = tempinvoice.length;
      if (leninvoice > 3) {
        if (this.vendoridtemp > 0 && this.form.projectName) {
          ExpenseService.checkInvoice(
            this.vendoridtemp,
            this.form.invoice_number,
            this.form.projectName.project_id
          )
            .then((response) => {
              const { data } = response;
              this.dupinvoice = data.Message;
              if (data.existingVarCount.varBalance) {
                this.isVarRequestApplicable = parseFloat(
                  data.existingVarCount.varBalance
                );
              } else {
                this.isVarRequestApplicable = 0;
              }

              if (data.user_balance_pcr) {
                this.isPcrRequestApplicable = parseFloat(data.user_balance_pcr);
              } else {
                this.isPcrRequestApplicable = 0;
              }
            })
            .catch((error) => {});
        }
      }
    },
    newVendor() {
      this.vendornotfound = "New Vendor request will be submitted!!";
    },
    gstCalculate(selected) {
      this.gst_name = selected.gst_name;
      this.gst_percentage = selected.gst_percentage;
      this.form.gst_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(2) : 0;

      let totalAmount = (parseFloat(selected.gst_percentage) * amount) / 100;
      this.form.gst_amount = parseFloat(totalAmount);
      this.totalAmount();
    },

    tdsCalculate(selected) {
      this.tds_name = selected.tds_name;
      this.tds_percentage = selected.tds_percentage;
      this.form.tds_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(2) : 0;

      let totalAmount = (parseFloat(selected.tds_percentage) * amount) / 100;
      this.form.tds_amount = parseFloat(totalAmount);
      this.totalAmount();
    },

    totalAmount() {
      let amount = this.form.amount != "" ? parseFloat(this.form.amount) : 0;
      this.form.total_amount =
        ((this.form.gst_amount ? parseFloat(this.form.gst_amount) : 0) * 10 +
          parseFloat(amount) * 10 -
          (this.form.tds_amount ? parseFloat(this.form.tds_amount) : 0) * 10) /
        10;
    },
    onSelectDate: function($event) {
      var date1 = new Date($event);
      var date2 = new Date(this.form.payment_due_date);
      var diffDays = date2.getDate() - date1.getDate();
      if (diffDays < 0) {
        this.form.payment_due_date = "";
      }
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.toDateDisable = false;
    },
    getShootLocationList: function() {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == false) {
          } else {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMasterList: function() {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.priority = data.data.priority;
            if (this.priority.length > 0) {
              this.form.priorityValue = this.priority[0];
            }

            this.paymentMode = data.data.payment_mode;
            if (this.paymentMode.length > 0) {
              this.form.modeOfPayment = this.paymentMode[0];
            }
            this.gstList = data.data.gst;
            this.tdsList = data.data.tds;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMapList: function(id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.approverList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    onSuggestSelect: function(selected) {
      // this.getSubCategory(selected);
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },
    getDataByUserAndProject(project_id, user_id) {
      projectService
        .getDataByUserAndProject(project_id, user_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            if (data.data.length > 0) {
              this.showOnBehalf =
                data.data[0].is_moderator_user ||
                data.data[0].is_admin_manager_user ||
                localStorage.getItem("UserRole") === "Admin"
                  ? true
                  : false;
            } else {
              this.showOnBehalf = false;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    onProjectSelect: function(selectedOption, id) {
      this.form.projectName = selectedOption;
      this.form.categoryValue = {};
      this.form.subcategoryValue = {};
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
      this.getDataByUserAndProject(
        this.project_id,
        window.localStorage.getItem("user_id")
      );
    },

    getAllCategoryListByProject: function(id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    submitForm: function() {
      this.$vs.loading();

      let onBehalfId;
      if (this.showOnBehalf) {
        onBehalfId = this.form.onBehalfId ? this.form.onBehalfId.user_id : 0;
      } else {
        onBehalfId = window.localStorage.getItem("user_id");
      }

      if (this.isVarRequestApplicable <= 0) {
        this.form.raised_from_var = false;
      }
      if (this.isPcrRequestApplicable <= 0) {
        this.form.raised_from_pcr = false;
      }

      let payload = {
        project_id: this.form.projectName
          ? this.form.projectName.project_id
          : 0,
        po_name: this.form.vendorName ? this.form.vendorName : "",
        item_name: "-",
        company_name: "-",
        customFieldValues: [],
        category_id: this.form.subcategoryValue
          ? this.form.subcategoryValue.category_id
          : 0,
        company_address: "-",
        price: this.form.amount,
        currency: "INR",
        sgst: "0",
        cgst: "0",
        total: this.form.amount,
        purchase_image: this.uploadedDocument,
        country_id: this.form.countryValue.country_id || null,
        city_id: this.form.cityValue.city_id || null,
        purchase_description: this.form.comment,
        purchase_status: "0",
        approved_percentage: "0",
        purchase_active: "1",
        location_id: this.form.shootLocation
          ? this.form.shootLocation.location_id
          : "",
        payment_mode: this.form.modeOfPayment
          ? this.form.modeOfPayment.payment_mode_id
          : "",
        payment_mode_description: "-",
        priority: this.form.priorityValue
          ? this.form.priorityValue.priority_id
          : "",
        default_alt_org_id: this.form.default_alt_org_id
          ? this.form.default_alt_org_id.default_alt_org_id
          : "",
        purchase_type: "expense",
        approver_id: "0",
        onBehalfId: this.form.onBehalfId ? this.form.onBehalfId.user_id : 0,
        onBehalfName: "",
        isAlreadyPaid: this.isAlreadyPaid ? 1 : 0,
        vendorName: this.form.vendorName,
        vendor_id: 0,
        invoice_number: this.form.invoice_number
          ? this.form.invoice_number
          : "",
        payment_due_date: this.form.payment_due_date
          ? this.form.payment_due_date
          : "",
        invoice_date: this.form.invoice_date ? this.form.invoice_date : "",
        gst_id: this.form.gst_id ? this.form.gst_id.gst_id : 0,
        gst_amount: this.form.gst_amount ? this.form.gst_amount : 0,
        tds_id: this.form.tds_id ? this.form.tds_id.tds_id : 0,
        tds_amount: this.form.tds_amount ? this.form.tds_amount : 0,
        total_amount: this.form.total_amount,
        raised_from_var:
          this.form.raised_from_var == true
            ? 1
            : this.form.raised_from_pcr == true
            ? 2
            : 0,
        is_var: 0,
      };

      // payload.lineItemData = this.form.subcategoryValue
      //   ? this.form.subcategoryValue.map((item) => ({
      //       category_id: item.category_id,
      //       unit: item.unit,
      //       rate: item.rate,
      //       amount: item.amount,
      //       total: item.total,
      //       description: item.description,
      //     }))
      //   : [];
      if (this.isCreditNote == 0) {
        payload.invoice_number = "";
        payload.payment_due_date = "";
        payload.invoice_date = "";
        payload.gst_id = "0";
        payload.gst_amount = 0;
        payload.tds_id = "0";
        payload.tds_amount = 0;
        payload.total_amount = this.form.amount;
      }
      if (this.vendorList) {
        let vendorCheck = this.vendorList.filter((x) => {
          return x.vendor_name == this.form.vendorName;
        });
        if (vendorCheck.length > 0) {
          payload["vendor_id"] = vendorCheck[0].vendor_id;
        }
      }

      ExpenseService.addExpense(payload)
        .then((response) => {
          const { data } = response;

          this.$vs.loading.close();
          if (data.Status == true) {
            this.$vs.notify({
              title: "Created!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.clearForm();
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getSubcategoryList: function(ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (data.data.length == 1) {
              this.form.subcategoryValue = this.subcategoryList[0];
            } else {
              this.form.subcategoryValue = "";
            }
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message || "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function(file) {},

    vdropzoneSuccessFile: function(file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },

    getCropedImage: function() {
      let blob = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.9);
      var newFile = this.dataURItoBlob(blob);
      newFile.name = this.selectedImageForCrop.name;
      this.vremoved(this.selectedImageForCrop);
      this.$refs.myVueDropzoneFile.removeFile(this.selectedImageForCrop);
      this.$refs.myVueDropzoneFile.addFile(newFile);
    },
    getImageToCrop: function(file) {
      this.selectedImageForCrop = null;
      this.showCropper = false;
      this.selectedImageForCrop = file;
      setTimeout(() => {
        this.showCropper = true;
      }, 500);
    },
    closeCropper: function() {
      this.popupCropImg = false;
    },
    openCropper: function() {
      this.popupCropImg = true;
    },
    clearFile: function() {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
      this.allSelectedImages = [];
    },
    vdropzoneComplete: function(response) {
      for (let i = 0; i < response.length; i++) {
        this.allSelectedImages.push(response[i]);
        this.selectedFilesUUID.push(response[i].upload.uuid);
      }
    },
    vdropzoneError: function(file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    vremoved: function(file) {
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);

      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
    },

    clearForm: function() {
      this.form = {
        raised_from_var: null,
        raised_from_pcr: null,
        invoice_number: "",
        payment_due_date: "",
        invoice_date: "",
        gst_id: "",
        gst_amount: 0,
        tds_id: "",
        tds_amount: 0,
        total_amount: 0,
        vendorName: "",
        categoryValue: "",
        amount: 0,
        comment: "",
        priorityValue: "",
        modeOfPayment: "",
        countryValue: "",
        onBehalfId: window.localStorage.getItem("user_id"),
        shootLocation: "",
        subcategoryValue: "",
      };
      this.isVarRequestApplicable = 0;
      this.isPcrRequestApplicable = 0;
      this.dupinvoice = "";
      this.submitStatus = true;
      this.reason = "";
      this.uploadedDocument = "-";

      this.clearFile();
      if (this.priority.length > 0) {
        this.form.priorityValue = this.priority[0];
      }
      if (this.paymentMode.length > 0) {
        this.form.modeOfPayment = this.paymentMode[0];
      }
      if (this.countryList.length > 0) {
        this.form.countryValue = this.countryList[0];
      }
    },

    // getCategory(city) {
    //   this.form.categoryValue = null;
    //   this.form.subcategoryValue = null;
    //   if (!this.form.projectName || !this.form.projectName.project_id) return;
    //   CategoryService.getProjectMainCategoriesByCity({
    //     project_id: this.form.projectName.project_id,
    //     city_id: city.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.categoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },

    // getSubCategory(category) {
    //   CategoryService.getProjectSubCategoriesByParentAndCity({
    //     parent_id: category.category_id,
    //     project_id: this.form.projectName.project_id,
    //     city_id: this.form.cityValue.city_id,
    //   })
    //     .then((response) => {
    //       if (response.data.Status) {
    //         this.subcategoryList = response.data.data;
    //       }
    //     })
    //     .catch((error) => console.log("Error => ", error.message));
    // },
  },
};
</script>
<style>
.popmodalViewR .vs-popup {
  width: 80% !important;
}
</style>
