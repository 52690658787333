<template>
  <div class="">
    <vx-card>
      <div class="flex flex-col sm:flex-row items-center justify-end">
        <div class="w-full sm:w-1/3 md:w-1/5">
          <multiselect
            label="project_name"
            trackBy="project_id"
            class="w-full"
            v-model="filters.project"
            :options="projectList"
            :searchable="true"
            open-direction="bottom"
            placeholder="Select Project"
            selected-label=""
            select-label=""
            deselect-label=""
            autocomplete="off"
            @select="refreshList"
          />
        </div>
      </div>

      <AgGridVue
        :gridOptions="gridOptions"
        :getRowId="(params) => params.data.purchase_id"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="listData"
        :rowSelection="'multiple'"
        suppressRowClickSelection
      />
      <div class="flex items-center justify-between">
        <vs-pagination
          :total="pagination.totalPages"
          v-model="pagination.currentPage"
        />

        <div class="mb-2">
          Show&nbsp;
          <multiselect
            :options="limits"
            v-model="limit"
            track-by="value"
            label="label"
            selected-label=""
            select-label=""
            deselect-label=""
            class="inline-block w-16"
            size="sm"
            @select="refreshList"
          />&nbsp;Entries Per Page
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ProjectService from "@/services/projectService.js";
import PurchaseOrderService from "@/services/purchaseOrderService.js";

export default {
  components: { AgGridVue },

  watch: {
    "pagination.currentPage"() {
      this.refreshList();
    },
  },
  data() {
    return {
      listData: [],
      gridOptions: {},
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Name",
          field: "po_name",
          minWidth: 200,
          filter: true,
        },
        {
          headerName: "User",
          field: "user_name",
          minWidth: 200,
          maxWidth: 300,
          filter: true,
        },
        {
          headerName: "VHC Code",
          field: "VHC_code",
          filter: true,
          width: 256,
        },
        {
          headerName: "Invoice Number",
          field: "invoice_number",
          filter: true,
          width: 256,
        },
        {
          headerName: "Total Amount",
          field: "total_amount",
          filter: true,
          width: 150,
        },
        {
          headerName: "Verified Status",
          field: "is_verified",
          filter: true,
          width: 275,
          cellRenderer: function(params) {
            return params.value == 1 ? `Verified` : "Not Verified";
          },
        },
      ],

      limits: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      limit: { value: 10, label: 10 },
      pagination: {
        currentPage: 1,
        maxPageNumbers: 4,
        totalPages: 1,
      },

      filters: {
        project: null,
      },
      projectList: [],
    };
  },

  methods: {
    refreshList() {
      this.$nextTick(() => {
        this.getListData();
      });
    },

    getListData() {
      let payload = {
        showOnlyDeleted: true,
        project_id: this.filters.project ? this.filters.project.project_id : 0,
        page_no: this.pagination.currentPage || 1,
        limit: this.limit ? this.limit.value : 10,
      };
      PurchaseOrderService.getPurchase(payload)
        .then((response) => {
          console.log("response => ", response);
          if (response.data.Status) {
            this.listData = response.data.data.data || [];
            if (response.data.data.pagination) {
              this.pagination.totalPages =
                response.data.data.pagination.last_page;
            } else {
              this.pagination.totalPages = 1;
            }
          } else {
            this.listData = [];
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    getProjectsList() {
      ProjectService.getAllProjects({}, localStorage.getItem("user_id"))
        .then((response) => {
          if (response.data.Status) {
            this.projectList = response.data.data;
          } else {
            this.projectList = [];
          }
        })
        .catch((error) => console.log("Error => ", error.message));
    },
  },

  mounted() {
    this.getProjectsList();
    this.getListData();
  },
};
</script>
